import React, { useState, useEffect, memo } from "react";
import Logo from "../../assets/images/logo.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import loginBg from "../../assets/images/loginBg.jpg";
import { resetPassword } from "../../api/adminLogin";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "../helper";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [authToken, setAuthToken] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    setAuthToken(window.location.pathname.split("/")[2]);
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleReset = () => {
    let params = { password: password };
    resetPassword(authToken, params)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        navigate("/");
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.message : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(249 249 249)",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div
        className="left-nav"
        style={{
          display: "flex",
          flex: 4,
          backgroundImage: `url(${loginBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <img
          src={Logo}
          style={{
            width: "9rem",
            height: "auto",
            maxWidth: "100%",
            top: "30px",
            left: "40px",
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
      <div
        className="right-nav"
        style={{
          padding: "2rem",
          mixHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(255, 255, 255)",
          flex: 1,
        }}
      >
        <Button
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: "0.875rem",
            color: "rgb(145, 85, 253)",
          }}
          onClick={() => navigate("/")}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            <ArrowBackIosNewOutlinedIcon fontSize="0.875rem" /> Back to login
          </p>
        </Button>
        <h2
          style={{
            color: "rgba(58, 53, 65, 0.87)",
            fontWeight: 600,
            lineHeight: "1.5",
            letterSpacing: " 0.15px",
            margin: "0px 0px 0.375rem",
          }}
        >
          Reset Password 🔒
        </h2>
        <p
          style={{
            margin: 0,
            color: "rgba(58, 53, 65, 0.68)",
            fontFamily: "'Inter', sans-serif",
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: "1.5",
            letterSpacing: " 0.15px",
          }}
        >
          Your new password must be different from previously used passwords
        </p>
        <>
          <FormControl
            variant="outlined"
            style={{ margin: "15px 0", width: "100%" }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              New Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
            />
          </FormControl>
          <Button
            variant="contained"
            fullWidth="100%"
            style={{
              backgroundColor: "rgb(128, 75, 223)",
              boxShadow: "rgba(58, 53, 65, 0.56) 0px 6px 18px -8px",
              margin: "0px 0px 1.75rem",
            }}
            onClick={handleReset}
          >
            RESET PASSWORD
          </Button>
        </>
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
    </div>
  );
}
export default memo(ResetPassword);
