import React, { useEffect, useState, memo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import {
  toastStyle,
  createAccess,
  updateAccess,
  SliceName,
  titleize,
} from "components/helper";
import "react-toastify/dist/ReactToastify.css";
import { getCookie, kickUser } from "components/helper/cookies";
import UserForm from "./userForm";
import { fetchRole, fetchUser, createUser, updateUser } from "api/users";
import { Skeleton } from "@mui/material";

const skeleton = [];
for (let i = 0; i < 10; i++) {
  skeleton.push(
    <div
      style={{
        flexBasis: "24.33%",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        color: "rgb(33, 43, 54)",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        // padding: "10px 24px",
      }}
      className="card-body"
    >
      <Skeleton sx={{ height: 80 }} animation="wave" variant="rectangular" />
      <Skeleton
        animation="wave"
        height={15}
        style={{ marginBottom: 6, marginTop: 6 }}
      />
      <Skeleton animation="wave" height={15} width="70%" />
    </div>
  );
}
const Role = ({ value }) => {
  const colors = {
    "Content Manager": "#9155FD",
    Admin: "#FFB400",
    "Sales Representative": "#56CA00",
    "Sales Manager": "#16B1FF",
  };
  const bgColors = {
    "Content Manager": "rgba(145, 85, 253, 0.12)",
    Admin: "rgba(255, 180, 0, 0.12)",
    "Sales Representative": "rgba(86, 202, 0, 0.12)",
    "Sales Manager": "rgba(22, 177, 255, 0.12)",
  };
  const color = colors[value] || "rgba(58, 53, 65, 0.54)";
  const bgColor = bgColors[value] || "black";
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div>
          <span
            style={{
              color: color,
              padding: "6px 15px 5px",
              borderRadius: 50,
              marginLeft: 5,
              whiteSpace: "nowrap",
              marginTop: "4px",
              marginBottom: "4px",
              backgroundColor: bgColor,
            }}
          >
            {titleize(value)}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
function User({ handleSearchshow, handleLoader }) {
  const [data, setData] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const [role, setRole] = useState("");
  const [rowData, setRowData] = useState({ status: "active" });
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  let controller = new AbortController();

  useEffect(() => {
    fetchUserRoles();
    handleSearchshow(false);
    handleLoader(true);
    fetchUsers();
    return () => {
      controller.abort();
    };
  }, []);
  const fetchUsers = () => {
    handleLoader(true);
    setLoading(true);
    fetchUser(getCookie("auth_token"), controller)
      .then(({ data }) => {
        setData(data);
        setLoading(false);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  };
  const fetchUserRoles = () => {
    fetchRole(getCookie("auth_token"))
      .then(({ data }) => {
        setRole(data);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  };

  const handleEdit = (data) => {
    setFormOpen(!formOpen);
    setRowData(data);
  };
  const handleOk = () => {
    const { name, email, phone, role_group, status, gender } = rowData;
    const action = rowData?.id ? updateUser : createUser;
    let requestBody = rowData?.id
      ? {
        user: {
          name,
          email,
          phone,
          role_group_id: role_group?.id ? role_group?.id : role_group,
          status,
          gender,
        },
      }
      : {
        user: {
          name,
          email,
          phone,
          role_group_id: role_group,
          status,
          send_welcome: "true",
          gender,
        },
      };
    setDisableSubmit(true);
    handleLoader(true);
    action(getCookie("auth_token"), requestBody, rowData?.id)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        setFormOpen(!formOpen);
        setRowData({ status: "active" });
        fetchUsers();
        handleLoader(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const handleCancel = () => {
    setFormOpen(!formOpen);
    setRowData({ status: "active" });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setRowData((preState) => ({ ...preState, [name]: value }));
    setDisableSubmit(false);
  };
  const handleToggle = (event, name) => {
    setRowData((preState) => ({ ...preState, [name]: event.target.value }));
    setDisableSubmit(false);
  };
  const formProps = {
    title: rowData?.id ? "Update User" : "Create User",

    rowData,
    formOpen,
    handleOk,
    handleCancel,
    handleChange,
    handleToggle,
    role,
    disableSubmit,
  };
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
        color: "rgba(58, 53, 65, 0.87)",
        borderRadius: "6px",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
        overflowX: "hidden !important",
        width: "auto",
        height: "auto !important",
      }}
    >
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Users
        </p>

        {createAccess("users") && (
          <Button
            variant="contained"
            style={{
              margin: " 0px 0.5rem 0.5rem 0px",
              backgroundColor: "rgb(128, 75, 223)",
              fontFamily: "'Inter', sans-serif",
              fontWeight: "500",
              fontSize: "0.8125rem",
              boxShadow: "rgba(58, 53, 65, 0.42) 0px 4px 8px -4px",
              textTransform: "uppercase",
            }}
            onClick={() => setFormOpen(!formOpen)}
          >
            New User
          </Button>
        )}
      </Box>
      <div style={{ height: "100%", padding: "1.2rem" }}>
        <Divider />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            padding: " 20px",
          }}
        >
          {loading ? (
            <>{skeleton}</>
          ) : (
            data.length > 0 &&
            data?.map((values, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flexBasis: "23%",
                    flexGrow: 0,
                    display: "flex",
                    color: "rgb(33, 43, 54)",
                    boxShadow:
                      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    borderRadius: " 16px",
                    zIndex: 0,
                    backgroundColor: "white",
                    padding: "10px",
                    minWidth: "19rem",
                    filter:
                      values?.status === "inactive"
                        ? " grayscale(100%)"
                        : "none",
                    opacity: values?.status === "inactive" ? 0.6 : "none",
                    position: "relative",
                    cursor: "pointer",
                    pointerEvents: disable ? "none" : "",
                  }}
                  className="user-card"
                  onClick={() =>
                    updateAccess("users")
                      ? handleEdit(values)
                      : (setDisable(true),
                        toast.error("Access Denied", toastStyle))
                  }
                >
                  <p
                    style={{
                      margin: " 0px 0.25rem 0px 0px",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "0.87rem",
                      fontWeight: 600,
                      color: "rgb(255, 199, 23)",
                      position: "absolute",
                      top: 7,
                      right: 2,
                    }}
                  >
                    <Role value={values?.role_group?.name} />
                  </p>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flex: "0.5",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={values?.image_url}
                        style={{
                          height: "70px",
                          objectFit: "scale-down",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px 10px",
                        flex: "1",
                      }}
                    >

                      <span
                        style={{
                          color: "rgb(33, 43, 54)",

                          fontFamily: "Inter, sans-serif",
                          fontWeight: 600,
                          fontSize: "1.2rem",
                        }}
                      >
                        {titleize(values.name)}
                      </span>
                      <h4
                        style={{
                          color: "rgb(33, 43, 54)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 400,
                          fontSize: "1rem",
                          margin: 0,
                        }}
                      >
                        {SliceName(values.email)}
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      {formOpen && <UserForm {...formProps} />}
      <ToastContainer style={{ zIndex: 999999 }} />
    </Paper>
  );
}
export default memo(User);
