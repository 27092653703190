import React, { Component } from "react";
import "antd/dist/antd.css";
import { Form, Upload } from "antd";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.handleRef = React.createRef();
    this.state = {
      selectedFile: null,
      selectedFileList: [],
      btn: false,
      imageSize: false,
    };
  }

  onChangeMulitple = (info) => {
    const image = info.fileList;
    this.setState({ selectedFile: image, selectedFileList: image });
  };
  requestParams = () => {
    const { selectedFile } = this.state;
    return (
      selectedFile &&
      selectedFile.map((image) => {
        return image?.originFileObj;
      })
    );
  };

  _btn = (bool) => this.setState({ btn: bool });
  render() {
    return (
      <React.Fragment>
        <Form.Item style={{ textTransform: "capitalize" }}>
          <Upload.Dragger
            name="file"
            customRequest={dummyRequest}
            fileList={this.state.selectedFileList}
            onChange={this.onChangeMulitple}
            required
            multiple={true}
          >
            <p className="ant-upload-drag-icon">
              <DriveFolderUploadOutlinedIcon style={{ fontSize: "5rem" }} />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single upload.</p>
          </Upload.Dragger>
        </Form.Item>
      </React.Fragment>
    );
  }
}

export default UploadFile;
