import React from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { tinyMcKey } from "config.js";

export const TinyMceField = ({ value, onChange, name, height, width }) => {
    return (
      <Editor
        value={value}
        apiKey={tinyMcKey}
        init={{
          height: `${height}`,
          width: `${width}`,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
        }}
        onEditorChange={onChange}
        name={name}
      />
    );
  };