import React, { memo, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import { Divider } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchCustomers } from "api/customers";
import { getCookie, kickUser } from "components/helper/cookies";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { presence, SliceName, titleize } from "components/helper";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import noDataFound from "assets/images/notFound.svg";

const skeleton = [];
for (let i = 0; i < 12; i++) {
  skeleton.push(
    <div
      style={{
        flexBasis: "24.33%",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        color: "rgb(33, 43, 54)",
        backgroundColor: "transparent",
        justifyContent: "space-between",
      }}
      className="card-body"
    >
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      <Skeleton
        animation="wave"
        height={15}
        style={{ marginBottom: 6, marginTop: 6 }}
      />
      <Skeleton animation="wave" height={15} width="70%" />
    </div>
  );
}

function Customer({ handleLoader, searchName, handleSearchshow }) {
  const [customers, setCustomers] = useState("");
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({ query: "" });
  const [total_entries, setTotal_entries] = useState();
  const [page, setPage] = useState(1);
  const perPage = 24;
  const path = window.location.pathname.split("/")[1];
  let controller = new AbortController();

  let navigate = useNavigate();
  useEffect(() => {
    handleLoader(true);
    handleSearchshow(true);
    handleSearch({ target: { value: searchName } });
  }, [searchName]);
  useEffect(() => {
    handleSearch({ target: { value: "" } });
  }, [path]);
  useEffect(() => {
    handleLoader(true);
    const params = {
      page,
      perPage,
    };
    fetchCustomers(getCookie("auth_token"), params, search, controller)
      .then(({ data, total_entries }) => {
        setCustomers(data);
        setTotal_entries(total_entries);
        setLoading(false);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
    return () => {
      controller.abort();
    };
  }, [page, search]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };
  const handlePageChange = (page) => {
    setPage(page);
    setLoading(true);
  };
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
      }}
    >
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Customers
          {total_entries > 0 && (
            <span style={{ color: "rgba(58, 53, 65, 0.87)" }}>
              ({total_entries})
            </span>
          )}
        </div>
      </Box>

      <div style={{ height: "100%", padding: "1.2rem", minHeight: "80vh" }}>
        <Divider />
        {total_entries === 0 && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
              width: "100%",
            }}
          >
            <img src={noDataFound} style={{ width: "40%" }} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            paddingTop: "20px",
          }}
        >
          {loading ? (
            <>{skeleton}</>
          ) : (
            customers.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flexBasis: "24.33%",
                    flexGrow: 0,
                    display: "flex",
                    flexDirection: "column",
                    color: "rgb(33, 43, 54)",
                    boxShadow:
                      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                    borderRadius: " 16px",
                    zIndex: 0,
                    backgroundColor: "white",
                    justifyContent: "space-between",
                    padding: "10px 24px",
                    minWidth: 350,
                    cursor: "pointer",
                  }}
                  className="customer-card"
                  onClick={() => {
                    navigate(`/customers/${value.id}`);
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      // padding: "1rem",
                      flex: 1,
                      display: "flex",
                      gap: 20,
                      justifyContent: "space-between",
                      alignItems: "center",
                      // flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifucontent: "center",
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: 10,
                        }}
                        src={value?.image_url}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 2,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "1rem",
                          color: "rgba(58, 53, 65, 0.6)",
                        }}
                      >
                        <PersonOutlinedIcon />
                        <span
                          style={{
                            color: "rgba(58, 53, 65, 0.6)",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: 1.5,
                            letterSpacing: " 0.15px",
                            margin: " 0px 0.5rem",
                          }}
                        >
                          {presence(titleize(value.name))}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "1rem",
                          color: "rgba(58, 53, 65, 0.6)",
                        }}
                      >
                        <SmartphoneOutlinedIcon />
                        <span
                          style={{
                            color: "rgba(58, 53, 65, 0.6)",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: 1.5,
                            letterSpacing: " 0.15px",
                            margin: " 0px 0.5rem",
                          }}
                        >
                          {presence(value.phone)}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          color: "rgba(58, 53, 65, 0.6)",
                        }}
                      >
                        <EmailOutlinedIcon />

                        <span
                          style={{
                            color: "rgba(58, 53, 65, 0.6)",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: 1.5,
                            letterSpacing: " 0.15px",
                            margin: " 0px 0.5rem",
                          }}
                        >
                          {presence(SliceName(value.email))}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Divider style={{ padding: 10 }} />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{ display: "flex", padding: "10px 0", flex: 1 }}
                      >
                        <span
                          style={{
                            backgroundColor: "rgba(145, 85, 253, 0.12)",
                            color: "rgb(145, 85, 253)",
                            borderRadius: 5,
                            overflow: "hidden",
                            fontSize: "1.25rem",
                            textAlign: "center",
                            height: 40,
                            width: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "1rem",
                          }}
                        >
                          <MapsUgcOutlinedIcon style={{ fontSize: "1.5rem" }} />
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 500,
                              fontSize: "1rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.87)",
                            }}
                          >
                            {value?.enquiry_count?.new_enquiry}
                          </span>
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 400,
                              fontSize: "0.75rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.6)",
                            }}
                          >
                            New Enquiry
                          </span>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", padding: "10px 0", flex: 1 }}
                      >
                        <span
                          style={{
                            backgroundColor: "rgba(255, 180, 0, 0.12)",
                            color: "rgb(255, 180, 0)",
                            borderRadius: 5,
                            overflow: "hidden",
                            fontSize: "1.25rem",
                            textAlign: "center",
                            height: 40,
                            width: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "1rem",
                          }}
                        >
                          <HourglassEmptyOutlinedIcon
                            style={{ fontSize: "1.5rem" }}
                          />
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 500,
                              fontSize: "1rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.87)",
                            }}
                          >
                            {value?.enquiry_count?.inprogress}
                          </span>
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 400,
                              fontSize: "0.75rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.6)",
                            }}
                          >
                            Inprogress
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{ display: "flex", padding: "10px 0", flex: 1 }}
                      >
                        <span
                          style={{
                            backgroundColor: "rgba(22, 177, 255, 0.12)",
                            color: "rgb(22, 177, 255)",
                            borderRadius: 5,
                            overflow: "hidden",
                            fontSize: "1.25rem",
                            textAlign: "center",
                            height: 40,
                            width: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "1rem",
                          }}
                        >
                          <MarkChatReadOutlinedIcon
                            style={{ fontSize: "1.5rem" }}
                          />
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 500,
                              fontSize: "1rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.87)",
                            }}
                          >
                            {value?.enquiry_count?.completed}
                          </span>
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 400,
                              fontSize: "0.75rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.6)",
                            }}
                          >
                            Completed
                          </span>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", padding: "10px 0", flex: 1 }}
                      >
                        <span
                          style={{
                            backgroundColor: "rgba(255, 76, 81, 0.12)",
                            color: "rgb(255, 76, 81)",
                            borderRadius: 5,
                            overflow: "hidden",
                            fontSize: "1.25rem",
                            textAlign: "center",
                            height: 40,
                            width: 40,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "1rem",
                          }}
                        >
                          <HighlightOffOutlinedIcon
                            style={{ fontSize: "1.5rem" }}
                          />
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 500,
                              fontSize: "1rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.87)",
                            }}
                          >
                            {value?.enquiry_count?.lost}
                          </span>
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif ",
                              fontWeight: 400,
                              fontSize: "0.75rem",
                              lineHeight: 1.6,
                              letterSpacing: "0.15px",
                              color: " rgba(58, 53, 65, 0.6)",
                            }}
                          >
                            Lost
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {total_entries != 0 && (
        <Pagination
          style={{ textAlign: "right", bottom: 30, marginRight: 20 }}
          defaultCurrent={1}
          current={page}
          defaultPageSize={perPage}
          onChange={handlePageChange}
          total={total_entries}
          showSizeChanger={false}
        />
      )}
      <ToastContainer style={{ zIndex: 999999999 }} />
    </Paper>
  );
}
export default memo(Customer);
