import makeRequest from "./index";

export const adminLogin = (requestParams) => {
  return makeRequest({
    uri: "/v1/admins/login",
    method: "POST",
    body: JSON.stringify(requestParams),
  });
};

export const adminOTP = (requestParams) => {
  return makeRequest({
    uri: "/v1/admins/forgot_password",
    method: "PUT",
    body: JSON.stringify(requestParams),
  });
};
export const verifyOTP = (token, requestParams) => {
  return makeRequest({
    uri: "/v1/admins/validate_otp",
    method: "PUT",
    body: JSON.stringify(requestParams),
    // authorization: false,
    baseAuthorization: token,
  });
};

export const resetPassword = (token, requestParams) => {

  return makeRequest({
    uri: "/v1/admins/reset_password",
    method: "PUT",
    body: JSON.stringify(requestParams),
    authorization: token,
  });
};

export function fetchRoleGroup(token) {
  return makeRequest({
    uri: `/v1/admins/roles`,
    method: "GET",
    authorization: token,
  });
}

