import React from "react";
import { titleize } from "components/helper";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import moment from "moment";

const Status = ({ value }) => {
  const colors = {
    new_enquiry: "#9155FD",
    inprogress: "#FFB400",
    completed: "#16B1FF",
    lost: "#FF4C51",
  };
  const bgColors = {
    new_enquiry: "rgba(145, 85, 253, 0.12)",
    inprogress: "rgba(255, 180, 0, 0.12)",
    completed: "rgba(22, 177, 255, 0.12)",
    lost: "rgba(255, 76, 81, 0.12)",
  };
  const color = colors[value] || "white";
  const bgColor = bgColors[value];
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div>
          <span
            style={{
              color: color,
              padding: "6px 15px 5px",
              borderRadius: 50,
              marginLeft: 5,
              whiteSpace: "nowrap",
              marginTop: "4px",
              marginBottom: "4px",
              backgroundColor: bgColor,
            }}
          >
            {titleize(value)}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
const Enquiry = ({ value }) => {
  const bgColors = {
    rental: "rgb(255, 180, 0)",
    leasing: "rgb(22, 177, 255)",
    pre_owned: "rgba(58, 53, 65, 0.87)",
  };
  const bgColor = bgColors[value];
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div>
          <span
            style={{
              color: "white",
              padding: "6px 15px 5px",
              borderRadius: 50,
              marginLeft: 5,
              whiteSpace: "nowrap",
              marginTop: "4px",
              marginBottom: "4px",
              backgroundColor: bgColor,
            }}
          >
            {titleize(value)}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
const Role = ({ value }) => {
  const colors = {
    "content Manager": "#9155FD",
    Admin: "#FFB400",
    "Sales Representative": "#56CA00",
    "Sales Manager": "#16B1FF",
  };
  const color = colors[value] || "rgba(58, 53, 65, 0.54)";
  return (
    <React.Fragment>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <span
          style={{
            color: color,
            borderRadius: 50,
            whiteSpace: "nowrap",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          ({titleize(value)})
        </span>
      </div>
    </React.Fragment>
  );
};
export const EnquiriesColumns = (editEnquiry, aboutEnquiry) => {
  return [
    {
      title: "Name",
      key: "name",
      align: "center",
      width: "10%",
      render: (row) => {
        return (
          <div style={{ display: "flex" }}>
            {row.image_url ? (
              <img
                src={row.image_url}
                style={{
                  height: "50px",
                  objectFit: "scale-down",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <div
                style={{
                  height: "50px",
                  width: "50px",
                  objectFit: "scale-down",
                  borderRadius: "50%",
                  backgroundColor: "rgba(145, 85, 253, 0.12)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "rgb(145, 85, 253)",
                }}
              >

                {titleize(row.name[0])}
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "center",
                textAlign: "left",
                marginLeft: "10px",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  color: "rgba(58, 53, 65, 0.87)",
                  fontSize: "16px",
                  lineHeight: "1.5715",
                  listStyle: "none",
                }}
              >
                {titleize(row.name)}
              </span>
              <span
                style={{
                  fontWeight: "400",
                  color: "rgba(58, 53, 65, 0.6)",
                  fontSize: "0.75rem",
                }}
              >

                {titleize(row.phone)}
              </span>
              <span
                style={{
                  fontWeight: "400",
                  color: "rgba(58, 53, 65, 0.6)",
                  fontSize: "0.75rem",
                }}
              >

                {titleize(row.email)}
              </span>
            </div>
          </div>
        );
      },
    },

    {
      dataIndex: "car_location",
      title: "Car Location",
      key: "car_location",
      align: "center",
      width: "20%",
      render: (car_location) => {
        return (
          <div
            style={{
              textAlign: "left",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {car_location}
          </div>
        );
      },
    },
    {
      dataIndex: "enquiry_type",
      title: "Enquiry Type",
      key: "enquiry_type",
      align: "center",
      render: (enquiry_type) => {
        return (
          <>
            {enquiry_type !== null ? (
              <Enquiry value={enquiry_type ? enquiry_type : "-"} />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      dataIndex: "assignee",
      title: "Assignee",
      key: "assignee",
      align: "center",
      render: (assignee) => {
        return (
          <>
            {assignee !== null ? (
              <div style={{ display: "flex" }}>
                {assignee.image_url ? (
                  <img
                    src={assignee.image_url}
                    style={{
                      height: "50px",
                      objectFit: "scale-down",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "scale-down",
                      borderRadius: "50%",
                      backgroundColor: "rgba(145, 85, 253, 0.12)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "rgb(145, 85, 253)",
                    }}
                  >

                    {titleize(assignee.name[0])}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    textAlign: "left",
                    marginLeft: "10px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      color: "rgba(58, 53, 65, 0.87)",
                      fontSize: "16px",
                      lineHeight: "1.5715",
                      listStyle: "none",
                    }}
                  >
                    {titleize(assignee.name)}
                  </span>

                  <span
                    style={{
                      fontWeight: "400",
                      color: "rgba(58, 53, 65, 0.6)",
                      fontSize: "0.75rem",
                    }}
                  >

                    <Role value={assignee.role_group.name} />
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      key: "status",
      align: "center",
      render: (status) => {
        return <>{status ? <Status value={status ? status : "-"} /> : "-"}</>;
      },
    },
    {
      title: "Created at / Updated at",
      align: "center",
      render: (row) => {
        return (
          <>
            <div>{moment(row?.created_at).format("ll")} /</div>
            <div>{row?.last_updated_at ? moment(row?.last_updated_at).format("ll") : "-"}</div>
          </>
        );
      },
    },
    {
      title: "Actions",
      align: "center",
      width: "12%",
      render: (row) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <RemoveRedEyeOutlinedIcon
              fontSize="small"
              onClick={() => aboutEnquiry(row, "true")}
              style={{ color: "rgba(58, 53, 65, 0.54)", cursor: "pointer" }}
            />

            <BorderColorOutlinedIcon
              fontSize="small"
              onClick={() => editEnquiry(row, "true")}
              style={{ color: "rgba(58, 53, 65, 0.54)", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];
};
