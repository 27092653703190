import React, { memo } from "react";
import Box from "@mui/material/Box";
import LayOut from "./layout";
import HeaderBar from "appBar";

function MainLayout(props) {
  const { children } = props;
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            minWidth: "250px",
            backgroundColor: "rgb(244, 245, 250)",
            height: "100vh",
          }}
        >
          <LayOut />
        </div>

        <div
          style={{
            overflow: "hidden",
            height: "100vh",
            backgroundColor: "rgb(244, 245, 250)",
            zIndex: 0,
            width: "100%",
          }}
        >
          <Box
            sx={{
              height: "100%",
              overflow: "scroll",
              backgroundColor: "rgb(244, 245, 250)",
            }}
          >
            <HeaderBar props={children}>{children}</HeaderBar>
          </Box>
        </div>
      </div>
    </div>
  );
}
export default memo(MainLayout);
