import makeRequest from "./index";

export function getProfile(token) {
  return makeRequest({
    uri: `/v1/admin/users/profile`,
    method: "GET",
    authorization: token,
  });
}

export function updateProfile(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/users/profile`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}
export function updatePassword(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/users/update_password`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}
