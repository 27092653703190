import makeRequest from "./index";

export function fetchOffers(token, params,search,controller) {
  return makeRequest({
    uri: `/v1/admin/offers?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller:controller,
  });
}

export function offerDetails(token, id) {
  return makeRequest({
    uri: `/v1/admin/offers/${id}`,
    method: "GET",
    authorization: token,
  });
}

export function createOffer(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/offers`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function updateOffer(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/offers/${id}`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}
