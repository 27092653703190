import React, { useState, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Modal, Pagination, Table } from "antd";
import { titleize } from "components/helper";
import { fetchEnquiries, fetchTeams, updateAssigne } from "api/enquiries";
import { getCookie, kickUser } from "components/helper/cookies";
import { EnquiriesColumns } from "columns/enquiriesColumns";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/inputs/customButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast, ToastContainer } from "react-toastify";
import { toastStyle, assignMember } from "components/helper";
import "react-toastify/dist/ReactToastify.css";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import NProgress from "nprogress";
function Enquiries({ handleLoader, searchName, handleSearchshow }) {
  const [enquiries, setEnquiries] = useState("");
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [rowData, setRowData] = useState({ status: "requested" });
  const [team, setTeam] = useState("");
  const [role, setRole] = useState("");
  let navigate = useNavigate();
  const [page, setPage] = useState(1);
  const perPage = 20;
  const [search, setSearch] = useState({ query: "" });
  const [total_entries, setTotal_entries] = useState();
  const path = window.location.pathname.split("/")[1];
  let controller = new AbortController();

  useEffect(() => {
    handleSearchshow(true);
    handleLoader(loading);
    handleSearch({ target: { value: searchName } });
  }, [searchName]);
  useEffect(() => {
    handleSearch({ target: { value: "" } });
  }, [path]);
  useEffect(() => {
    handleLoader(loading);
    enquires();
    fetchTeam();
    return () => {
      controller.abort();
    };
  }, [page, search]);
  const enquires = () => {
    handleLoader(true);
    const params = {
      page,
      perPage,
    };
    fetchEnquiries(getCookie("auth_token"), params, search, controller)
      .then(({ data, total_entries }) => {
        setEnquiries(data);
        setTotal_entries(total_entries);
        setLoading(false);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  };
  const fetchTeam = async () => {
    await fetchTeams(getCookie("auth_token"))
      .then(({ data }) => {
        setTeam(data);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  };
  const aboutEnquiry = (data) => {
    navigate(`/enquiries/${data.id}`);
  };
  const handleOk = async () => {
    setDisabled(true);
    handleLoader(true);
    let params = {
      user_id: role ? role : rowData?.assignee?.id,
      status: rowData?.status,
    };

    await updateAssigne(getCookie("auth_token"), params, rowData?.id)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        setFormOpen(false);
        setRowData("");
        enquires();
        handleLoader(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };
  const handleTeam = (e) => {
    setDisabled(false);
    setRole(e.target.value);
  };
  const handleStatus = (e) => {
    setDisabled(false);
    setRowData((prevState) => ({ ...prevState, status: e.target.value }));
  };
  const handleCancel = () => {
    setFormOpen(false);
    setRole("");
  };
  const handlePageChange = (page) => {
    setPage(page);
    setLoading(true);
  };
  const editEnquiry = (row, key) => {
    setFormOpen(key);
    setRowData(row);
  };
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
      }}
    >
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Enquiries
          {total_entries > 0 && (
            <span style={{ color: "rgba(58, 53, 65, 0.87)" }}>
              ({total_entries})
            </span>
          )}
        </div>
      </Box>

      <div style={{ height: "100%", padding: "1.2rem" }}>
        <Divider />
        <Table
          columns={EnquiriesColumns(editEnquiry, aboutEnquiry)}
          dataSource={enquiries}
          rowKey={(record) => record.id}
          loading={loading}
          style={{ padding: 20 }}
          pagination={false}
        />
        {formOpen && (
          <Modal
            title="Update Enquiry"
            open={formOpen}
            centered
            style={{ padding: 10, minWidth: "40%" }}
            closable={false}
            footer={[
              <CustomButton
                key="cancle"
                color="error"
                text="Cancel"
                onClick={handleCancel}
              />,
              <CustomButton
                key="submit"
                color="success"
                text="Submit"
                onClick={handleOk}
                disabled={disabled}
              />,
            ]}
          >
            <div
              style={{
                display: "flex",
                gap: 20,
                padding: 10,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  color: "rgb(33, 43, 54)",
                  boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 5px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  borderRadius: " 16px",
                  zIndex: 0,
                  backgroundColor: "white",
                  padding: "10px",
                  overflow: "auto",
                  maxHeight: "80vh",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    padding: "1rem",
                    flex: 1,
                    display: "flex",
                    gap: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifucontent: "center",
                      alignItems: "center",
                      marginRight: 10,
                    }}
                  >
                    <img
                      style={{
                        width: "120px",
                        height: "120px",
                        borderRadius: 10,
                      }}
                      src={rowData.image_url}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >

                      <span
                        style={{
                          color: "rgba(58, 53, 65, 0.87)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 500,
                          fontSize: "1.25rem",
                        }}
                      >
                        {titleize(rowData.name)}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 2,
                      flexDirection: "column",
                    }}
                  >

                    <div
                      style={{
                        display: "flex",
                        marginBottom: "1rem",
                        color: "rgba(58, 53, 65, 0.6)",
                      }}
                    >
                      <SmartphoneOutlinedIcon />
                      <p
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                          margin: " 0px 0.5rem",
                        }}
                      >
                        Phone:{"  "}
                      </p>
                      <span
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 400,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                        }}
                      >
                        {rowData.phone}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "1rem",
                        color: "rgba(58, 53, 65, 0.6)",
                      }}
                    >
                      <EmailOutlinedIcon />
                      <p
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                          margin: " 0px 0.5rem",
                        }}
                      >
                        Email:{"  "}
                      </p>
                      <span
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 400,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                        }}
                      >
                        {rowData.email}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "1rem",
                        color: "rgba(58, 53, 65, 0.6)",
                      }}
                    >
                      <LocationOnOutlinedIcon />
                      <p
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                          margin: " 0px 0.5rem",
                        }}
                      >
                        Location:{"  "}
                      </p>
                      <span
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 400,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                        }}
                      >
                        {titleize(rowData?.car_location)}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "1rem",
                        color: "rgba(58, 53, 65, 0.6)",
                      }}
                    >
                      <LocalPhoneOutlinedIcon />
                      <p
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 600,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                          margin: " 0px 0.5rem",
                        }}
                      >
                        Enquiry Type:{"  "}
                      </p>
                      <span
                        style={{
                          color: "rgba(58, 53, 65, 0.6)",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 400,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          letterSpacing: " 0.15px",
                        }}
                      >
                        {titleize(rowData.enquiry_type)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 20,
                padding: "20px 10px",
                flexDirection: "column",
              }}
            >
              {assignMember("enquiries") ? (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Assignee Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role ? role : rowData?.assignee?.id}
                    label="User Assigne"
                    onChange={handleTeam}
                    name="assigne"
                  >
                    {team?.map((value, index) => (
                      <MenuItem key={index} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Assignee Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role ? role : rowData?.assignee?.id}
                    label="User Assigne"
                    onChange={handleTeam}
                    name="assigne"
                    readOnly={true}
                  >
                    {team?.map((value, index) => (
                      <MenuItem key={index} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowData?.status ? rowData?.status : ""}
                  label="Status"
                  onChange={handleStatus}
                  name="status"
                >
                  <MenuItem value={"new_enquiry"}>New Enquiry</MenuItem>
                  <MenuItem value={"inprogress"}>Inprogress</MenuItem>
                  <MenuItem value={"completed"}>Completed</MenuItem>
                  <MenuItem value={"lost"}>Lost</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Modal>
        )}
      </div>
      {total_entries != 0 && (
        <Pagination
          style={{ textAlign: "right", bottom: 20, marginRight: 30 }}
          defaultCurrent={1}
          current={page}
          defaultPageSize={perPage}
          onChange={handlePageChange}
          total={total_entries}
          showSizeChanger={false}
        />
      )}
      <ToastContainer style={{ zIndex: 9999999 }} />
    </Paper>
  );
}
export default memo(Enquiries);
