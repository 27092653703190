import React, { useState, useEffect, memo } from "react";
import Logo from "../../assets/images/logo.png";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "../helper";
import { adminLogin } from "../../api/adminLogin";
import { setCookie } from "../../components/helper/cookies";
import loginBg from "../../assets/images/loginBg.jpg";
import { LoadingButton } from "@mui/lab";

function Login() {
  const [values, setValues] = useState({
    user_name: "",
    password: "",
    showPassword: false,
    checked: false,
  });
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.user_name !== "" && localStorage.checked) {
      setValues((preState) => ({
        ...preState,
        user_name: localStorage.user_name,
        password: localStorage.password,
        checked: localStorage.checked,
      }));
    }
  }, []);

  const handleChange = (prop) => (event) => {
    if (prop === "checked") {
      setValues({ ...values, [prop]: event.target.checked });
    } else {
      setValues({ ...values, [prop]: event.target.value });
      setDisable(false);
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleLogin = (e) => {
    setLoading(true);
    e.preventDefault();
    setDisable(true);
    const { user_name, password, checked } = values;
    let params = { user_name: user_name, password: password };
    adminLogin(params)
      .then(({ message, data }) => {
        if (checked && user_name) {
          localStorage.user_name = user_name;
          localStorage.password = password;
          localStorage.checked = checked;
        }
        setCookie("auth_token", data?.auth_token);
        setCookie("name", data?.name);
        setCookie("role_group", data?.role_group?.name);
        setCookie("profile_Image", data?.image_url);
        setLoading(false);
        toast.success(message, toastStyle);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        if (err[0] === 401) {
          toast.error(
            err[1] ? "Invaild Email or Password" : "Something not right",
            toastStyle
          );
        } else {
          toast.error(
            err[1] ? err[1].data[0] : "Something not right",
            toastStyle
          );
        }
      });
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(249 249 249)",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div
        className="left-nav"
        style={{
          display: "flex",
          flex: 4,
          backgroundImage: `url(${loginBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <img
          src={Logo}
          style={{
            width: "9rem",
            height: "auto",
            maxWidth: "100%",
            top: "30px",
            left: "40px",
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
      <div
        className="right-nav"
        style={{
          padding: "2rem",
          mixHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(255, 255, 255)",
          flex: 1,
        }}
      >
        <h4
          style={{
            color: "rgba(58, 53, 65, 0.87)",
            fontFamily: "'Inter', sans-serif",
            fontWeight: 600,
            fontSize: "1.49rem",
            lineHeight: "1.5",
            letterSpacing: " 0.15px",
            margin: "0px 0px 0.375rem",
          }}
        >
          Welcome to Sayarti!
        </h4>
        <p
          style={{
            margin: 0,
            color: "rgba(58, 53, 65, 0.68)",
            fontFamily: "'Inter', sans-serif",
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: "1.5",
            letterSpacing: " 0.15px",
          }}
        >
          Please sign-in to your account and start the adventure
        </p>
        <form onSubmit={handleLogin}>
          <TextField
            id="outlined-basic"
            handleLogin
            label="Email"
            name="user_name"
            value={values.user_name}
            style={{ margin: "15px 0", width: "100%" }}
            onChange={handleChange("user_name")}
          />
          <FormControl
            variant="outlined"
            style={{ margin: "15px 0", width: "100%" }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.checked}
                  onChange={handleChange("checked")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Remember Me"
            />
            <Button onClick={() => navigate("/forgot-password")}>

              <p
                style={{
                  fontSize: "0.875rem",
                  color: "rgb(145, 85, 253)",
                  marginBottom: 0,
                }}
              >
                Forget Password?
              </p>
            </Button>
          </div>
          {/* <Button
            variant="contained"
            style={{
              backgroundColor: "rgb(128, 75, 223)",
              boxShadow: "rgba(58, 53, 65, 0.56) 0px 6px 18px -8px",
              margin: "0px 0px 1.75rem",
              width: "100%",
            }}
            type="submit"
            // onSubmit={handleLogin}
            disabled={disable}
          >
            LOGIN
          </Button> */}
          <LoadingButton
            loading={loading}
            // loadingPosition="middle"
            variant="contained"
            style={{
              backgroundColor: "rgb(128, 75, 223)",
              boxShadow: "rgba(58, 53, 65, 0.56) 0px 6px 18px -8px",
              margin: "0px 0px 1.75rem",
              width: "100%",
            }}
            type="submit"
            // onSubmit={handleLogin}
            disabled={disable}
          >
            LOGIN
          </LoadingButton>
        </form>
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
    </div>
  );
}
export default memo(Login);
