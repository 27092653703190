import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const styles = {
  default: {
    color: "#323232",
    padding: "5px 16px",
    marginLeft: "15px",
    backgroundColor: "white",
    border: "1px solid #5e72e4",
    boxShadow: "0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "#3CC140",
      boxShadow: " 0px 5px 5px -4px",
    },
    "&:active": {
      borderColor: "#323250",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  success: {
    color: "white",
    border: "1px solid rgb(128, 75, 223)",
    backgroundColor: "rgb(128, 75, 223)",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "600",
    "&:hover": {
      boxShadow: "rgba(58, 53, 65, 0.56) 0px 6px 18px -8px",
    },
    "&:active": {
      boxShadow: "rgba(58, 53, 65, 0.56) 0px 6px 18px -8px",
      borderColor: "rgb(128, 75, 223)  ",
    },
  },
  error: {
    color: "white",
    border: "1px solid rgb(230, 56, 26)",
    backgroundColor: "rgb(230, 56, 26)",
    fontFamily: "'Inter', sans-serif",
    fontWeight: "600",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
};

const CustomButton = (props) => {
  let style = styles.default;
  const { color, text, disabled } = props;
  switch (color) {
    case "success":
      style = { ...style, ...styles.success };
      break;
    case "error":
      style = { ...style, ...styles.error };
      break;
    default:
      break;
  }
  return (
    <Button style={style} {...props} disabled={disabled}>
      {text}
    </Button>
  );
};

CustomButton.prototype = {
  color: PropTypes.oneOf(["success", "secondary", "error"]),
  text: PropTypes.string.isRequired,
};

export default CustomButton;
