import { useNProgress } from '@tanem/react-nprogress'
import PropTypes from 'prop-types'
import React from 'react'

const Progress = ({ isAnimating }) => {
    const { animationDuration, isFinished, progress } = useNProgress({
        isAnimating,
    })

    return (
        <Container isFinished={isFinished} animationDuration={animationDuration}>
            <Bar progress={progress} animationDuration={animationDuration} />
        </Container>
    )
}

const Bar = ({ progress, animationDuration }) => (
    <div
        style={{
            background: '#804BDF',
            height: 2,
            left: 0,
            marginLeft: `${(-1 + progress) * 100}%`,
            position: 'fixed',
            top: 0,
            transition: `margin-left ${animationDuration}ms linear`,
            width: '100%',
            zIndex: 1031,
        }}
    >
        <div
            style={{
                boxShadow: '0 0 10px #29d, 0 0 5px #29d',
                display: 'block',
                height: '100%',
                opacity: 1,
                position: 'absolute',
                right: 0,
                transform: 'rotate(3deg) translate(0px, -4px)',
                width: 100,
            }}
        />
    </div>
)

const Container = ({ children, isFinished, animationDuration }) => (
    <div
        style={{
            opacity: isFinished ? 0 : 1,
            pointerEvents: 'none',
            transition: `opacity ${animationDuration}ms linear`,
        }}
    >
        {children}
    </div>
)

Container.propTypes = {
    animationDuration: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    isFinished: PropTypes.bool.isRequired,
}

Bar.propTypes = {
    animationDuration: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
}


Progress.propTypes = {
    isAnimating: PropTypes.bool.isRequired,
}

export default Progress
