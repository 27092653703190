import React, { useState, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import { getCookie, kickUser } from "../src/components/helper/cookies";
// import { UserImage } from "config";
import Progress from "components/progress";

function HeaderBar(props) {
  const [searchValue, setSearchValue] = useState("");
  const [profileSettings, setProfileSettings] = useState(null);
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const { children } = props;
  // const profileImage = Math.floor(Math.random() * UserImage.length);
  const path = window.location.pathname.split("/")[1];
  useEffect(() => {
    setSearchValue("");
    setLoading(true);
  }, [path]);
  const handleOpenUserMenu = (event) => {
    setProfileSettings(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setProfileSettings(null);
  };

  const handleSearchvalue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleChange = (callBack) => {
    handleChildsearch = callBack;
  };
  const handleSearchshow = (show) => {
    setShowSearch(show);
  };
  const handleLoader = (load) => {
    setLoading(load);
  };
  const childrenWithProps = {
    ...children,
    props: {
      handleChange,
      handleLoader,
      handleSearchshow,
      searchName: searchValue,
    },
  };
  let profile = getCookie("name");
  let role = getCookie("role_group");
  let Image = getCookie("profile_Image");
  return (
    <>
      <Progress isAnimating={loading} />

      <div
        style={{
          backgroundColor: "rgb(244, 245, 250)",
        }}
        className="nav-header"
        id="navbar"
      >
        <Container
          maxWidth="xl"
          style={{ paddingRight: 0, paddingLeft: 0, display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 1,
            }}
          >
            {showSearch && (
              <TextField
                className="search"
                placeholder="Search..."
                style={{
                  width: "50%",
                  borderStyle: "none",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">

                      <SearchOutlinedIcon />
                    </InputAdornment>
                    // import Toolbar from "@mui/material/Toolbar";
                  ),
                }}
                value={searchValue}
                autoComplete="off"
                onChange={handleSearchvalue}
              />
            )}
          </div>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontSize: "1.125rem",
                  letterSpacing: "0.15px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  color: "rgba(58, 53, 65, 0.87)",
                  transition:
                    "opacity 0.25s ease-in-out 0s, margin 0.25s ease-in-out 0s",
                  opacity: 1,
                  // textAlign:"center",
                  margin: 0,
                }}
              >
                {profile}
              </p>
              <p
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontSize: "0.89rem",
                  letterSpacing: "0.15px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "uppercase",
                  color: "rgba(58, 53, 65, 0.68)",
                  transition:
                    "opacity 0.25s ease-in-out 0s, margin 0.25s ease-in-out 0s",
                  opacity: 1,
                  // textAlign:"center",
                  margin: 0,
                  textAlign: "right"
                }}
              >
                {role}
              </p>
            </div>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar alt="Profile" src={Image} sizes="30px" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: -1, ml: 2 }}
              id="menu-appbar"
              anchorEl={profileSettings}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(profileSettings)}
              onClose={handleCloseUserMenu}
            >
              <div
                style={{
                  opacity: 1,
                  transform: "none",
                  transition:
                    "opacity 331ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 220ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  top: "16px",
                  left: "1501px",
                  transformOrigin: "230px 36px 0px",
                }}
              >
                <div style={{ padding: "0.5rem 1rem 0.75rem" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar alt="Profile" src={Image} />
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "0.75rem",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <p className="profile-name">{profile}</p>
                      <p className="profile-role">{role}</p>
                    </div>
                  </div>
                </div>
                <Divider />
                <li className="profile-list">
                  <div
                    onClick={() => navigate("/profile")}
                    className="profile-menu"
                  >
                    <PersonOutlineOutlinedIcon
                      style={{ marginRight: "0.5rem" }}
                    />
                    Profile
                  </div>
                </li>
                <li className="profile-list">
                  <div onClick={() => kickUser()} className="profile-menu">
                    <LoginOutlinedIcon style={{ marginRight: "0.5rem" }} />
                    Logout
                  </div>
                </li>
              </div>
            </Menu>
          </Box>
        </Container>
        <div>{childrenWithProps}</div>
      </div>
    </>
  );
}
export default memo(HeaderBar);
