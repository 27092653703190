import React, { memo } from "react";
import { Modal } from "antd";
import { Col, Row } from "antd";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CustomButton from "components/inputs/customButton";

function UserForm({
  title,
  rowData,
  formOpen,
  handleOk,
  handleCancel,
  handleChange,
  role,
  handleToggle,
  disableSubmit,
}) {
  return (
    <div>

      <Modal
        title={title}
        open={formOpen}
        style={{ padding: 10, minWidth: 700 }}
        closable={false}
        footer={[
          <CustomButton
            key="cancle"
            color="error"
            text="Cancel"
            onClick={handleCancel}
          />,
          <CustomButton
            key="submit"
            color="success"
            text="Submit"
            onClick={handleOk}
            disabled={disableSubmit}
          />,
        ]}
      >
        <Row spacing={5}>
          <Col xs={12} sm={12} md={12} style={{ padding: "10px" }}>
            <TextField
              id="standard-basic"
              label="Name"
              variant="outlined"
              name="name"
              onChange={handleChange}
              value={rowData?.name ? rowData?.name : ""}
              style={{
                width: "100%",
              }}
              required
            />
          </Col>
          <Col item xs={12} sm={12} md={12} style={{ padding: "10px" }}>
            <TextField
              id="standard-basic"
              label="Email"
              variant="outlined"
              name="email"
              onChange={handleChange}
              value={rowData?.email ? rowData?.email : ""}
              style={{
                width: "100%",
              }}
              required
            />
          </Col>

          <Col item xs={24} sm={12} md={12} style={{ padding: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">User Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  rowData?.role_group?.id
                    ? rowData?.role_group?.id
                    : rowData?.role_group
                }
                label="User Role"
                onChange={handleChange}
                name="role_group"
              >
                {role?.map((value, index) => (
                  <MenuItem key={index} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col item xs={24} sm={12} md={12} style={{ padding: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowData?.gender ? rowData?.gender : ""}
                label="Gender"
                onChange={handleChange}
                name="gender"
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col item xs={24} sm={12} md={12} style={{ padding: "10px" }}>
            <ToggleButtonGroup
              exclusive
              onChange={(event) => handleToggle(event, "status")}
              color="primary"
              name="status"
              value={rowData?.status ? rowData?.status : ""}
            >
              <ToggleButton
                value="active"
                style={{ width: "100%", padding: "10px 30px" }}
              >
                Active
              </ToggleButton>
              <ToggleButton
                value="inactive"
                style={{ width: "100%", padding: "10px 30px" }}
              >
                Inactive
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
export default memo(UserForm);
