import React, { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Pagination } from "antd";
import { Modal } from "antd";
import { Button, Skeleton, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CustomButton from "components/inputs/customButton";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import { rentalCar } from "api/car";
import { getCookie } from "components/helper/cookies";
import { toast, ToastContainer } from "react-toastify";
import { toastStyle } from "components/helper";

const skeleton = [];
for (let i = 0; i < 12; i++) {
  skeleton.push(
    <div
      key={i}
      style={{
        flexBasis: "24.33%",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        color: "rgb(33, 43, 54)",
        backgroundColor: "transparent",
        justifyContent: "space-between",
      }}
      className="card-body"
    >
      <Skeleton sx={{ height: 90 }} animation="wave" variant="rectangular" />
      <Skeleton
        animation="wave"
        height={15}
        style={{ marginBottom: 6, marginTop: 6 }}
      />
      <Skeleton animation="wave" height={15} width="70%" />
    </div>
  );
}

function OfferModal({ handleOk, handleCancel, defaultSelected }) {
  const [loader, setLoader] = useState(true);
  const [carList, setCarList] = useState([]);
  const [total_entries, setTotal_entries] = useState(0);
  const [page, setPage] = useState(1);
  const perPage = 24;
  const [selectedCars, setSelectedCars] = useState(defaultSelected);
  const [search, setSearch] = useState({ query: "" });

  useEffect(() => {
    rentalCars();
    setLoader(true);
  }, [page, search]);

  const rentalCars = async () => {
    const params = {
      page,
      perPage,
    };
    await rentalCar(getCookie("auth_token"), params, search)
      .then(({ data, total_entries }) => {
        setCarList(data);
        setTotal_entries(total_entries);
        setLoader(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };

  const handlePageChange = (page) => {
    setPage(page);
    setLoader(true);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };
  const handleSelectAll = () => {
  let isAllSelected = carList.some((value) =>
      selectedCars.map((val) => val.id).includes(value.id)
    );

    const dataId = carList.map((value) => {
      return value.id;
    });
    if (isAllSelected) {
      const difference = selectedCars.filter((x) => !dataId.includes(x.id));
      setSelectedCars(difference);
    } else {
      const mergedArray = selectedCars.concat(carList);
      const uniqueValues = [
        ...new Map(mergedArray.map((item) => [item["id"], item])).values(),
      ];
      setSelectedCars(uniqueValues);
    }
  };

  const handleToggle = (value) => () => {
    const isSelected = selectedCars.some((ele) => value.id == ele.id);
    let newCars = [...selectedCars];

    if (isSelected) {
      newCars = newCars.filter((val) => val.id != value.id);
    } else {
      newCars.push(value);
    }
    setSelectedCars(newCars);
  };

  return (
    <Modal
      title="Select Car"
      open
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ padding: 10, minWidth: "80%" }}
      closable={false}
      footer={[
        <CustomButton
          key="cancle"
          color="error"
          text="Cancel"
          onClick={handleCancel}
        />,
        <CustomButton
          key="submit"
          color="success"
          text="Select"
          onClick={() => handleOk(selectedCars)}
        />,
      ]}
      className="offer-modal"
    >
      <div
        style={{
          height: "50vh",
          overflow: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {" "}
          <TextField
            className="search"
            placeholder="Search..."
            style={{
              width: "50%",
              borderStyle: "none",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <div>
            <Button variant="contained" onClick={handleSelectAll}>
              Select All
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            paddingTop: "20px",
            bgcolor: "background.paper",
            borderWidth: "4px",
          }}
        >
          {loader ? (
            <>{skeleton}</>
          ) : (
            <>
              {carList.map((value, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      flexBasis: "31%",
                      flexGrow: 0,
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      backgroundColor: " rgb(255, 255, 255)",
                      color: "rgba(58, 53, 65, 0.87)",
                      transition:
                        "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      borderRadius: "6px",
                      overflow: "hidden",
                      boxShadow: " rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                      minHeight: "125px",
                    }}
                    className="selected-cars"
                    onClick={handleToggle(value, "rentals")}
                    name="car_list"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <div style={{ flex: 0.5 }}>
                        {value?.title_image_url || value?.title_image?.url ? (
                          <img
                            src={
                              value?.title_image?.url
                                ? value?.title_image?.url
                                : value?.title_image_url
                            }
                            style={{
                              height: "100px",
                              width: "150px",
                              objectFit: "scale-down",
                            }}
                          />
                        ) : (
                          <ImageSearchOutlinedIcon
                            style={{
                              height: "100px",
                              width: "150px",
                              objectFit: "scale-down",
                            }}
                          />
                        )}
                      </div>
                      <div style={{ flex: 1, height: "100%" }}>
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Inter, sans-serif",
                            fontSize: "1rem",
                            fontWeight: 400,
                            color: "rgba(58, 53, 65, 0.87)",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {value.brand}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Inter, sans-serif",
                            fontSize: "1rem",
                            fontWeight: 700,
                            color: "rgba(58, 53, 65, 0.87)",
                            display: "flex",
                            justifyContent: "flex-start",
                            textAlign: "left",
                          }}
                        >
                          {value.en_model_name}
                        </p>
                      </div>
                      {selectedCars
                        .map((isChecked) => isChecked.id)
                        .includes(value.id) && (
                        // : checked.indexOf(value) !== -1
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "#56CA00",
                            fontWeight: "700",
                            backgroundColor: "rgba(86, 202, 0, 0.12)",
                            borderRadius: "100%",
                            textAlign: "center",
                            height: "20px",
                          }}
                        >
                          <DoneIcon fontSize="small" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {total_entries != 0 && (
            <Pagination
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginRight: 10,
              }}
              defaultCurrent={1}
              current={page}
              defaultPageSize={perPage}
              onChange={handlePageChange}
              total={total_entries}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
    </Modal>
  );
}
export default OfferModal;
