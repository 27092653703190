import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import customerBg from "assets/images/customerBanner.png";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { customerDetails } from "api/customers";
import { toast, ToastContainer } from "react-toastify";
import { presence, titleize, toastStyle } from "components/helper";
import "react-toastify/dist/ReactToastify.css";
import { getCookie, kickUser } from "components/helper/cookies";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import clock from "assets/images/clock.svg";
import moment from "moment";

const Status = ({ value }) => {
  const colors = {
    new_enquiry: "#9155FD",
    inprogress: "#FFB400",
    completed: "#16B1FF",
    lost: "#FF4C51",
  };
  const bgColors = {
    new_enquiry: "rgba(145, 85, 253, 0.12)",
    inprogress: "rgba(255, 180, 0, 0.12)",
    completed: "rgba(22, 177, 255, 0.12)",
    lost: "rgba(255, 76, 81, 0.12)",
  };
  const color = colors[value] || "white";
  const bgColor = bgColors[value];
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div>
          <span
            style={{
              color: color,
              padding: "6px 15px 5px",
              borderRadius: 50,
              marginLeft: 5,
              whiteSpace: "nowrap",
              marginTop: "4px",
              marginBottom: "4px",
              backgroundColor: bgColor,
            }}
          >
            {titleize(value)}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
const skeleton = [];
for (let i = 0; i < 12; i++) {
  skeleton.push(
    <div
      style={{
        flexBasis: "24.33%",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        color: "rgb(33, 43, 54)",
        backgroundColor: "transparent",
        justifyContent: "space-between",
      }}
      className="enquiry_card"
    >
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      <Skeleton
        animation="wave"
        height={15}
        style={{ marginBottom: 6, marginTop: 6 }}
      />
      <Skeleton animation="wave" height={15} width="70%" />
    </div>
  );
}
export default function CustomerDetails({ handleSearchshow, handleLoader }) {
  const [loading, setLoading] = useState(true);
  const [stateData, setStateData] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    handleSearchshow(false);
    handleLoader(loading);
    const path = window.location.pathname.split("/")[2];
    customerDetails(getCookie("auth_token"), path)
      .then(({ data }) => {
        setStateData(data);
        setLoading(false);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  }, []);
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(244, 245, 250)",
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textTransform: "capitalize",
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Customer Details
        </div>
      </div>
      <Divider />

      <div
        style={{
          padding: "10px 20px ",
          backgroundColor: "rgb(255, 255, 255)",
          margin: "20px ",
          boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
          borderRadius: " 16px",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
      >
        <div>
          <img src={customerBg} height={150} width={"100%"} />
          <div
            style={{
              padding: "0rem 1.25rem 1.25rem",
              marginTop: "-2rem",
              display: "flex",
            }}
          >
            {loading ? (
              <Skeleton variant="rectangular" width={120} height={120} />
            ) : (
              <img
                src={stateData?.image_url}
                height={120}
                width={120}
                style={{
                  border: "5px solid rgb(255, 255, 255)",
                  borderRadius: "6px",
                }}
              />
            )}
            <div
              style={{
                marginLeft: "1.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Skeleton variant="rectangular" height={15} />
              ) : (
                <p
                  style={{
                    margin: " 0px 0px 1rem",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    lineHeight: 1.334,
                    color: "rgba(58, 53, 65, 0.87)",
                  }}
                >
                  {titleize(stateData?.name)}
                </p>
              )}
              {loading ? (
                <Skeleton variant="rectangular" height={15} />
              ) : (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      color: "rgba(58, 53, 65, 0.6)",
                    }}
                  >
                    <SmartphoneOutlinedIcon />
                    <p
                      style={{
                        color: "rgba(58, 53, 65, 0.6)",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        fontSize: "1rem",
                        lineHeight: 1.5,
                        letterSpacing: " 0.15px",
                        margin: " 0px 0.5rem",
                      }}
                    >
                      Phone:{"  "}
                    </p>
                    <span
                      style={{
                        color: "rgba(58, 53, 65, 0.6)",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: 1.5,
                        letterSpacing: " 0.15px",
                      }}
                    >
                      {presence(stateData?.phone)}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1rem",
                      color: "rgba(58, 53, 65, 0.6)",
                      marginLeft: 10,
                    }}
                  >
                    <EmailOutlinedIcon />
                    <p
                      style={{
                        color: "rgba(58, 53, 65, 0.6)",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        fontSize: "1rem",
                        lineHeight: 1.5,
                        letterSpacing: " 0.15px",
                        margin: " 0px 0.5rem",
                      }}
                    >
                      Email:{"  "}
                    </p>
                    <span
                      style={{
                        color: "rgba(58, 53, 65, 0.6)",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: 1.5,
                        letterSpacing: " 0.15px",
                      }}
                    >
                      {presence(stateData?.email)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Divider />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
            paddingTop: "20px",
          }}
        >
          {loading ? (
            <>{skeleton}</>
          ) : (
            stateData?.enquiries &&
            stateData?.enquiries.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    margin: "10px ",
                    boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                    borderRadius: " 16px",
                    transition:
                      "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    flexBasis: "23%",
                    flexGrow: 0,
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  className="enquiry_card"
                  onClick={() => {
                    navigate(`/enquiries/${value.id}`);
                  }}
                >
                  <div
                    style={{
                      padding: "1.25rem",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        margin: 0,
                        fontFamily: "Inter, sans-serif",
                        fontSize: "1.25rem",
                        color: "rgba(58, 53, 65, 0.87)",
                        fontWeight: 500,
                      }}
                    >
                      {titleize(value?.enquiry_type)}
                    </span>
                    <span>
                      <Status value={value?.status} />
                    </span>
                  </div>
                  <div style={{ height: "13vh", overflow: "auto" }}>
                    {value?.vehicle_list !== null &&
                      value?.vehicle_list.map((vehicle, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              padding: "0 1rem",
                              display: "flex",
                              marginBottom: "1rem",
                            }}
                          >
                            <img
                              src={vehicle?.title_image_url}
                              height={60}
                              //   width={120}
                              style={{
                                borderRadius: "100%",
                                marginRight: " 0.875rem",
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "0.875rem",
                                  color: "rgba(58, 53, 65, 0.87)",
                                  letterSpacing: "0.4px",
                                  fontWeight: 600,
                                }}
                              >
                                {vehicle?.brand}
                              </span>
                              <span
                                style={{
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "0.75rem",
                                  color: "rgba(58, 53, 65, 0.6)",
                                  fontWeight: 400,
                                }}
                              >
                                {vehicle?.en_model_name}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <Divider style={{ margin: "0 10px" }} />
                  <div
                    style={{
                      padding: "0.8rem 1.25rem",
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "0.89rem",
                      color: "rgba(58, 53, 65, 0.6)",
                      fontWeight: 400,
                    }}
                  >
                    <img
                      src={clock}
                      style={{ color: "rgba(58, 53, 65, 0.6)" }}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      Last Enquired:
                      {value?.last_updated_at
                        ? moment(value?.last_updated_at).format("lll")
                        : "-"}
                    </span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
    </Paper>
  );
}
