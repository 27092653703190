import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, kickUser } from "components/helper/cookies";
import { leasingCar } from "api/car";
import CardTemplate from "components/template/card";
import { toast, ToastContainer } from "react-toastify";
import { toastStyle } from "components/helper";
import "react-toastify/dist/ReactToastify.css";
import { createAccess } from "components/helper";

function LeasingCar({ searchName, handleSearchshow, handleLoader }) {
  const [data, setData] = useState([]);
  const [total_entries, setTotal_entries] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({ query: "" });
  const [page, setPage] = useState(1);
  const perPage = 24;
  let navigate = useNavigate();
  const path = window.location.pathname.split("/")[1];
  let controller = new AbortController();
  useEffect(() => {
    handleSearchshow(true);
    handleLoader(true);
    setLoading(true);
    handleSearch({ target: { value: searchName } });
  }, [searchName]);
  useEffect(() => {
    handleSearch({ target: { value: "" } });
  }, [path]);
  useEffect(() => {
    handleLoader(true);
    setLoading(true);
    const params = {
      page,
      perPage,
    };
    leasingCar(getCookie("auth_token"), params, search, controller)
      .then(({ data, total_entries }) => {
        setData(data);
        setTotal_entries(total_entries);
        setLoading(false);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
    return () => {
      controller.abort();
    };
  }, [path, search]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };
  const handleCreate = () => {
    navigate("/leasingcar/new");
  };
  const handlePageChange = (page) => {
    setPage(page);
    setLoading(true);
    handleLoader(true);
  };

  const lesasingProps = {
    data,
    total_entries,
    loading,
    title: "Leasing Car",
    handleCreate,
    createAction: createAccess("leasings"),
    menu: "leasings",
    handlePageChange,
    perPage,
    page,
  };

  return (
    <>
      <CardTemplate {...lesasingProps} key={"leasing"} />
      <ToastContainer style={{ zIndex: 9999999 }} />
    </>
  );
}

export default memo(LeasingCar);
