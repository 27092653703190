import React, { useState, memo } from "react";
import Logo from "../../assets/images/logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import loginBg from "../../assets/images/loginBg.jpg";
import { adminOTP, verifyOTP } from "../../api/adminLogin";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastStyle } from "../helper";

function Forget() {
  const [values, setValues] = useState({
    email: "",
    otp_token: "",
  });
  const [verify, setVerify] = useState(false);
  const [otpAuth, setOtpAuth] = useState("");
  const [disable, setDisable] = useState(false);
  let navigate = useNavigate();
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setDisable(false);
  };
  const handleOTP = () => {
    const { email } = values;
    let params = { user_name: email };
    setDisable(true);
    adminOTP(params)
      .then(({ message, data }) => {
        toast.success(message, toastStyle);
        setOtpAuth(data);
        setVerify(true);
      })
      .catch((err) => {
        setDisable(true);
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.message : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const handleVerify = () => {
    const { otp_token } = values;
    setDisable(true);
    let params = { otp_token: otp_token };
    verifyOTP(otpAuth, params)
      .then(({ message, data }) => {
        toast.success(message, toastStyle);
        setVerify(true);
        navigate(`/reset-password/${data.auth_token}`);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.message : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(249 249 249)",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div
        className="left-nav"
        style={{
          display: "flex",
          flex: 4,
          backgroundImage: `url(${loginBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <img
          src={Logo}
          style={{
            width: "9rem",
            height: "auto",
            maxWidth: "100%",
            top: "30px",
            left: "40px",
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
      <div
        className="right-nav"
        style={{
          padding: "2rem",
          mixHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(255, 255, 255)",
          flex: 1,
        }}
      >
        <Button
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: "0.875rem",
            color: "rgb(145, 85, 253)",
          }}
          onClick={() => navigate("/")}
        >
          <p
            style={{
              textAlign: "center",
            }}
          >
            <ArrowBackIosNewOutlinedIcon fontSize="0.875rem" /> Back to login
          </p>
        </Button>
        <h2
          style={{
            color: "rgba(58, 53, 65, 0.87)",
            fontWeight: 600,
            lineHeight: "1.5",
            letterSpacing: " 0.15px",
            margin: "0px 0px 0.375rem",
          }}
        >
          Forgot Password? 🔒
        </h2>
        <p
          style={{
            margin: 0,
            color: "rgba(58, 53, 65, 0.68)",
            fontFamily: "'Inter', sans-serif",
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: "1.5",
            letterSpacing: " 0.15px",
          }}
        >
          Enter your email and we′ll send you instructions to reset your
          password
        </p>
        {verify ? (
          <>
            <TextField
              id="outlined-basic"
              label="OTP"
              style={{ margin: "15px 0", width: "100%" }}
              name="otp"
              value={values.otp_token}
              onChange={handleChange("otp_token")}
            />
            <Button
              variant="contained"
              fullWidth="100%"
              style={{
                backgroundColor: "rgb(128, 75, 223)",
                boxShadow: "rgba(58, 53, 65, 0.56) 0px 6px 18px -8px",
                margin: "0px 0px 1.75rem",
              }}
              onClick={handleVerify}
              disabled={disable}
            >
              VERIFY OTP
            </Button>
          </>
        ) : (
          <>
            <TextField
              id="outlined-basic"
              label="Email"
              style={{ margin: "15px 0", width: "100%" }}
              name="email"
              onChange={handleChange("email")}
              value={values.email}
            />
            <Button
              variant="contained"
              fullWidth="100%"
              style={{
                backgroundColor: "rgb(128, 75, 223)",
                boxShadow: "rgba(58, 53, 65, 0.56) 0px 6px 18px -8px",
                margin: "0px 0px 1.75rem",
              }}
              onClick={handleOTP}
              disabled={disable}
            >
              SEND OTP
            </Button>
          </>
        )}
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
    </div>
  );
}
export default memo(Forget);
