import makeRequest from "./index";

export function fetchEnquiries(token, params, search,controller) {
  return makeRequest({
    uri: `/v1/admin/enquiries?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller:controller,
  });
}
export function fetchTeams(token) {
  return makeRequest({
    uri: `/v1/admin/enquiries/sales_users`,
    method: "GET",
    authorization: token,
  });
}
export function updateAssigne(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/enquiries/${id}/assign_member`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

// export function updateStatus(token, requestBody, id) {
//   return makeRequest({
//     uri: `/v1/admin/enquiries/${id}/update_status`,
//     method: "PUT",
//     authorization: token,
//     body: JSON.stringify(requestBody),
//   });
// }

export function enquiriesDetails(token, id) {
  return makeRequest({
    uri: `/v1/admin/enquiries/${id}`,
    method: "GET",
    authorization: token,
  });
}

export function sendReply(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/enquiries/${id}/conversation`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function getResponses(token, id) {
  return makeRequest({
    uri: `/v1/admin/enquiries/${id}/conversations`,
    method: "GET",
    authorization: token,
  });
}

export function updateStatus(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/enquiries/${id}/update_status`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}
