import React, { useState, useEffect, memo } from "react";
import TextField from "@mui/material/TextField";
import { Box, Divider, Paper } from "@mui/material";
import { getCookie, kickUser, setCookie } from "components/helper/cookies";
import { getProfile, updateProfile, updatePassword } from "api/profile";
import CustomButton from "components/inputs/customButton";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { titleize, toastStyle } from "components/helper";
import InputAdornment from "@mui/material/InputAdornment";
import Avatar from "assets/images/profileAvatar.png";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";

const Role = ({ value }) => {
  const colors = {
    "content Manager": "#9155FD",
    Admin: "#FFB400",
    "Sales Representative": "#56CA00",
    "Sales Manager": "#16B1FF",
  };
  const bgColors = {
    "content Manager": "rgba(145, 85, 253, 0.12)",
    Admin: "rgba(255, 180, 0, 0.12)",
    "Sales Representative": "rgba(22, 177, 255, 0.12)",
    "Sales Manager": "rgba(255, 76, 81, 0.12)",
  };
  const color = colors[value] || "rgba(58, 53, 65, 0.54)";
  const bgColor = bgColors[value];
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div>
          <span
            style={{
              color: color,
              borderRadius: 50,
              whiteSpace: "nowrap",
              backgroundColor: bgColor,
              padding: "6px 15px 5px",
            }}
          >
            {titleize(value)}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
function Profile({ handleSearchshow, handleLoader }) {
  const [stateData, setStateData] = useState({
    name: "",
    email: "",
    current_password: "",
    new_password: "",
  });
  const [disableProfile, setDisableProfile] = useState(false);
  const [disablePassword, setDisablePassword] = useState(false);
  let role = getCookie("role_group");
  let Image = getCookie("profile_Image");
  let profile = getCookie("name");
  useEffect(() => {
    handleLoader(true);
    handleSearchshow(false);
    getProfile(getCookie("auth_token"))
      .then(({ data }) => {
        setStateData(data);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  }, []);
  const handleChange = (event, name) => {
    setStateData((prevState) => ({ ...prevState, [name]: event.target.value }));
    name === "name" || name === "email"
      ? setDisableProfile(false)
      : setDisablePassword(false);
  };
  const passwordSubmit = () => {
    let password = {
      current_password: stateData?.current_password,
      new_password: stateData?.new_password,
    };
    handleLoader(true);
    setDisablePassword(true);
    updatePassword(getCookie("auth_token"), password)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        handleLoader(false);
        setTimeout(() => {
          window.location.pathname = `/rentalcar`;
        }, 2000);
      })
      .catch((err) => {
        if (err) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const handleSubmit = () => {
    let profileUpdate = {
      name: stateData?.name,
      email: stateData?.email,
    };
    handleLoader(true);
    setDisableProfile(true);
    updateProfile(getCookie("auth_token"), profileUpdate)
      .then(({ data, message }) => {
        toast.success(message, toastStyle);
        setCookie("name", data?.name);
        setCookie("role_group", data?.role_group?.name);
        handleLoader(false);
        setTimeout(() => {
          window.location.pathname = `/rentalcar`;
        }, 2000);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
      }}
    >
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Profile
        </div>
        <div>
          <CustomButton
            color="success"
            onClick={handleSubmit}
            text={"Update Profile"}
            disabled={disableProfile}
          />
        </div>
      </Box>

      <div style={{ height: "100%" }}>
        <Divider />
        <div
          style={{
            display: "flex",
            padding: "1.2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "0.5",
              flexDirection: "column",
              color: "rgb(33, 43, 54)",
              boxShadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              borderRadius: " 16px",
              zIndex: 0,
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                padding: "10px 24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ padding: "10px", display: "flex" }}>
                <img
                  src={Image ? Image : Avatar}
                  style={{
                    height: "100px",
                    objectFit: "scale-down",
                    borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(58, 53, 65, 0.87)",
                      fontFamily: " Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: "1.5rem",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <PermIdentityOutlinedIcon style={{ marginRight: 10 }} />
                    {profile}
                  </div>
                  <div
                    style={{
                      color: "rgba(58, 53, 65, 0.87)",
                      fontFamily: " Inter, sans-serif",
                      fontWeight: 500,
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <StarBorderOutlinedIcon style={{ marginRight: 10 }} />
                    <Role value={role} />
                  </div>
                </div>
              </div>

              <div style={{ padding: "10px" }}>
                <TextField
                  id="outlined-start-adornment"
                  label="Full Name"
                  value={stateData.name ? stateData?.name : ""}
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  name="name"
                  onChange={(e) => {
                    handleChange(e, "name");
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PermIdentityOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ padding: "10px" }}>
                <TextField
                  id="standard-basic"
                  label="Email"
                  value={stateData.email ? stateData?.email : ""}
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  name="email"
                  onChange={(e) => {
                    handleChange(e, "email");
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ padding: "10px" }}>
                <TextField
                  id="standard-basic"
                  type="password"
                  label="Current Password"
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  value={
                    stateData.current_password
                      ? stateData?.current_password
                      : ""
                  }
                  name="current_password"
                  onChange={(e) => {
                    handleChange(e, "current_password");
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HttpsOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ padding: "10px" }}>
                <TextField
                  id="standard-basic"
                  type="password"
                  label="New Password"
                  variant="outlined"
                  style={{
                    width: "100%",
                  }}
                  value={stateData.new_password ? stateData?.new_password : ""}
                  name="new_password"
                  onChange={(e) => {
                    handleChange(e, "new_password");
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOpenOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <CustomButton
                  color="success"
                  onClick={passwordSubmit}
                  text={"Change Password"}
                  disabled={disablePassword}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer style={{ zIndex: 999999 }} />
    </Paper>
  );
}
export default memo(Profile);
