import React, { Component } from "react";
import { Modal, Pagination } from "antd";
import { fetchImageData } from "../../api/image";
import { getCookie } from "../helper/cookies";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import CustomButton from "components/inputs/customButton";

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      perPage: 16,
      search: {
        query: "",
      },
      total_entries: 0,
      image_id: null,
      image_name: null,
      loading: true,
      multiImage: {
        multiImageID: [],
        selectedImage: [],
      },
    };
  }

  componentDidMount() {
    this.fetchImage();
  }

  fetchImage() {
    const { page, perPage, search } = this.state;
    const params = {
      page,
      perPage,
    };
    fetchImageData(getCookie("auth_token"), params, search).then(
      ({ data, total_entries }) => {
        this.setState({
          data,
          total_entries,
          loading: false,
        });
      }
    );
  }

  handlePageChange = (page) => {
    this.setState({ page, loading: true }, this.fetchImage);
  };

  selectImage = (image_id, image_name) => {
    this.setState({ image_id, image_name });
  };
  handleMultiSelect = (image_id, url) => {
    const { multiImageID, selectedImage } = this.state.multiImage;
    if (selectedImage.includes(image_id)) {
      selectedImage.splice(selectedImage.indexOf(image_id), 1);
      multiImageID.splice(
        multiImageID.findIndex((data) => data?.image_id == image_id),
        1
      );
    } else {
      selectedImage.push(image_id);
      multiImageID.push(url);
    }
    this.setState({ multiImage: { multiImageID, selectedImage } });
  };

  render() {
    const {
      data,
      page,
      perPage,
      total_entries,
      image_id,
      image_name,
      loading,
      multiImage,
    } = this.state;

    const { handlePageChange, selectImage, handleMultiSelect } = this;
    const { showImageModal, handleModel, onModalOk, multi, imageName } = this.props;
    const action = multi ? handleMultiSelect : selectImage;
    const skeleton = [];
    for (let i = 0; i < 15; i++) {
      skeleton.push(
        <Grid xs={3} container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} key={skeleton}>
            <Skeleton
              sx={{ bgcolor: "grey.300" }}
              height={100}
              style={{ width: "100%" }}
              variant="rectangular"
            />
            <Skeleton
              animation="wave"
              height={15}
              style={{ marginBottom: 6, marginTop: 6 }}
            />
            <Skeleton animation="wave" height={15} width="70%" />
          </Grid>
        </Grid>
      );
    }
    const { selectedImage } = multiImage;
    return (
      <Modal
        visible={showImageModal}
        centered
        title="Image"
        closable={false}
        width="75%"
        footer={[
          <CustomButton
            key="cancle"
            color="error"
            text="Cancel"
            onClick={handleModel}
          />,
          <CustomButton
            key="submit"
            color="success"
            text="Select"
            onClick={() =>
              onModalOk(multi ? multiImage : image_name, imageName)
            }
          />,
        ]}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
          {loading ? (
            <>{skeleton} </>
          ) : (
            data?.map((value, index) => {
              let color = multi
                ? selectedImage?.includes(value?.id)
                  ? "#42C3AA"
                  : "#bebebe"
                : image_id === value?.id
                ? "#42C3AA"
                : "#bebebe";
              return (
                <div
                  key={index}
                  style={{
                    width: "23%",
                    height: "150px",
                    border: `3px solid ${color}`,
                    backgroundImage: `url("${value.url}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  onClick={() => action(value.id, value)}
                ></div>
              );
            })
          )}
        </div>
        <div style={{ padding: "15px 0px 0px 0px" }}>
          <Pagination
            style={{ textAlign: "right" }}
            defaultCurrent={1}
            current={page}
            defaultPageSize={perPage}
            onChange={handlePageChange}
            total={total_entries}
            showSizeChanger={false}
          />
        </div>
      </Modal>
    );
  }
}

export default ImageModal;
