import React, { useState, useEffect, memo } from "react";
import { toast, ToastContainer } from "react-toastify";
import { titleize, toastStyle } from "components/helper";
import "react-toastify/dist/ReactToastify.css";
import {
  enquiriesDetails,
  getResponses,
  sendReply,
  updateStatus,
} from "api/enquiries";
import { getCookie } from "components/helper/cookies";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  TextField,
} from "@mui/material";
import moment from "moment";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function EnquiriesForm({ handleSearchshow, handleLoader }) {
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(true);
  const id = window.location.pathname.split("/")[2];
  const auth_token = getCookie("auth_token");
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    handleLoader(loading);
    handleSearchshow(false);
    enquiriesDetail();
    getResponse();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      getResponse();
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  const enquiriesDetail = () => {
    enquiriesDetails(auth_token, id)
      .then(({ data }) => {
        setEnquiryDetails(data);
        setLoading(false);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const getResponse = () => {
    getResponses(auth_token, id)
      .then(({ data }) => {
        setMessage(data);
        setLoading(false);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const handleChange = (e) => {
    setResponse(e.target.value);
    setDisable(false);
  };
  const handleStatus = (e) => {
    setEnquiryDetails((prevState) => ({
      ...prevState,
      status: e.target.value,
    }));
    let params = { status: e.target.value };
    updateStatus(auth_token, params, id)
      .then(({ message }) => {
        toast.success(message, toastStyle);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  const handleSubmit = () => {
    let params = { content: response };
    handleLoader(true);
    setDisable(true);
    sendReply(auth_token, params, id)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        getResponse();
        handleLoader(false);
        setResponse("");
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
      }}
    >
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Enquiries
        </div>
      </Box>

      <div style={{ height: "100%" }}>
        <Divider />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ display: "flex", padding: "1.2rem" }}>
            <div
              style={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                color: "rgb(33, 43, 54)",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                borderRadius: " 16px",
                zIndex: 0,
                backgroundColor: "white",
                padding: "10px 24px",
                overflow: "auto",
                maxHeight: "80vh",
              }}
              className="enquiry-card"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                {enquiryDetails.image_url ? (
                  <img
                    src={
                      enquiryDetails.image_url
                    }
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: 10,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "scale-down",
                      borderRadius: 10,
                      backgroundColor: "rgba(145, 85, 253, 0.12)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "rgb(145, 85, 253)",
                      fontSize: 40,
                    }}
                  >

                    {titleize(enquiryDetails?.name && enquiryDetails?.name[1])}
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    marginLeft: "1.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgba(58, 53, 65, 0.87)",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 500,
                        fontSize: "1.5rem",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {titleize(enquiryDetails.name)}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "rgba(58, 53, 65, 0.6)",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <SmartphoneOutlinedIcon
                      fontSize="small"
                      style={{ marginRight: "0.25rem" }}
                    />

                    <p
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        fontSize: "1rem",
                        margin: "0 0 0 0.25rem",
                      }}
                    >
                      {enquiryDetails.phone}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "rgba(58, 53, 65, 0.6)",
                      flexWrap: "wrap",
                    }}
                  >
                    <EmailOutlinedIcon
                      fontSize="small"
                      style={{ marginRight: "0.25rem" }}
                    />

                    <p
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        fontSize: "1rem",
                        margin: "0 0 0 0.25rem",
                      }}
                    >
                      {enquiryDetails.email}
                    </p>
                  </div>
                </div>
              </div>
              <Divider style={{ marginBottom: 20 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  marginBottom: "1.5rem",
                }}
              >
                <FormControl
                  style={{ width: "150px", marginBottom: "1rem" }}
                  className="status-select"
                >
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="status-select"
                    value={enquiryDetails.status ? enquiryDetails.status : ""}
                    label="Status"
                    onChange={handleStatus}
                    name="status"
                  >
                    <MenuItem value={"new_enquiry"}>New Enquiry</MenuItem>
                    <MenuItem value={"inprogress"}>Inprogress</MenuItem>
                    <MenuItem value={"completed"}>Completed</MenuItem>
                    <MenuItem value={"lost"}>Lost</MenuItem>
                  </Select>
                </FormControl>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: "10px",
                    color: "rgba(58, 53, 65, 0.6)",
                  }}
                >
                  <span
                    style={{
                      color: "rgba(58, 53, 65, 0.6)",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      letterSpacing: " 0.15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InsertInvitationOutlinedIcon
                      style={{ marginRight: "0.5rem" }}
                    />
                    {moment(enquiryDetails.start_date).format("DD-MMM-YYYY")}
                    {"-"}
                    {moment(enquiryDetails.end_date).format("DD-MMM-YYYY")}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                    color: "rgba(58, 53, 65, 0.6)",
                  }}
                >
                  <ManageAccountsOutlinedIcon />
                  <p
                    style={{
                      color: "rgba(58, 53, 65, 0.6)",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      letterSpacing: " 0.15px",
                      margin: " 0px 0.5rem",
                    }}
                  >
                    Assignee :{"  "}
                  </p>
                  {enquiryDetails.assignee
                    ? enquiryDetails?.assignee?.name
                    : ""}
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1rem",
                    color: "rgba(58, 53, 65, 0.6)",
                  }}
                >
                  <LocalPhoneOutlinedIcon />
                  <p
                    style={{
                      color: "rgba(58, 53, 65, 0.6)",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      letterSpacing: " 0.15px",
                      margin: " 0px 0.5rem",
                    }}
                  >
                    Enquiry Type :{"  "}
                  </p>
                  <span
                    style={{
                      color: "rgba(58, 53, 65, 0.6)",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      letterSpacing: " 0.15px",
                    }}
                  >
                    {titleize(enquiryDetails.enquiry_type)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",

                    color: "rgba(58, 53, 65, 0.6)",
                  }}
                >
                  <LocationOnOutlinedIcon />
                  <p
                    style={{
                      color: "rgba(58, 53, 65, 0.6)",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      letterSpacing: " 0.15px",
                      margin: " 0px 0.5rem",
                    }}
                  >
                    Location :{"  "}
                  </p>
                </div>
                <p
                  style={{
                    color: "rgba(58, 53, 65, 0.6)",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                    fontSize: "1rem",
                    lineHeight: 1.5,
                    letterSpacing: " 0.15px",
                    margin: " 1rem 2rem ",
                    textAlign: "left",
                  }}
                >
                  {titleize(enquiryDetails?.car_location)}
                </p>

                <div
                  style={{
                    display: "flex",
                    marginBottom: "0.5rem",
                    color: "rgba(58, 53, 65, 0.6)",
                  }}
                >
                  <TextsmsOutlinedIcon />
                  <p
                    style={{
                      color: "rgba(58, 53, 65, 0.6)",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                      letterSpacing: " 0.15px",
                      margin: " 0px 0.5rem",
                    }}
                  >
                    Description :{"  "}
                  </p>
                </div>
                <p
                  style={{
                    color: "rgba(58, 53, 65, 0.6)",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 400,
                    fontSize: "1rem",
                    lineHeight: 1.5,
                    letterSpacing: " 0.15px",
                    margin: " 0px 2rem ",
                    textAlign: "left",
                  }}
                >
                  {enquiryDetails.description ? enquiryDetails.description : ""}
                </p>
              </div>
              <Divider style={{ marginBottom: 20 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    color: "rgba(58, 53, 65, 0.6)",
                    fontSize: "1rem",
                    textAlign: "center",
                    lineHeight: 1.66,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "uppercase",
                    margin: "0px 0px 1.25rem",
                    letterSpacing: "0.4px",
                  }}
                >
                  <ShoppingCartOutlinedIcon
                    fontSize="small"
                    style={{ marginRight: 5 }}
                  />
                  Enquired Cars
                </span>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: "10px  ",
                    width: "100%",
                  }}
                >
                  {enquiryDetails?.vehicle_list !== null &&
                    enquiryDetails?.vehicle_list !== undefined &&
                    enquiryDetails?.vehicle_list.map((value, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            backgroundColor: " rgb(255, 255, 255)",
                            color: "rgba(58, 53, 65, 0.87)",
                            transition:
                              "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            borderRadius: "6px",
                            overflow: "hidden",
                            boxShadow:
                              " rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                            width: "100%",
                            margin: "5px auto",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <div style={{ padding: "0 20px" }}>
                              {value?.title_image_url ? (
                                <img
                                  src={value?.title_image_url}
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    objectFit: "scale-down",
                                  }}
                                />
                              ) : (
                                <ImageSearchOutlinedIcon
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    objectFit: "scale-down",
                                  }}
                                />
                              )}
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <p
                                style={{
                                  margin: 0,
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "1rem",
                                  fontWeight: 400,
                                  color: "rgba(58, 53, 65, 0.87)",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {value.brand}
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontFamily: "Inter, sans-serif",
                                  fontSize: "1.2rem",
                                  fontWeight: 700,
                                  color: "rgba(58, 53, 65, 0.87)",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {value.en_model_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 4,
                display: "flex",
                flexDirection: "column",
                color: "rgb(33, 43, 54)",
                boxShadow:
                  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                borderRadius: " 16px",
                zIndex: 0,
                backgroundColor: "rgba(58, 53, 65, 0.04)",
                padding: "20px 24px",
                maxHeight: "80vh",
              }}
              className="enquiry-chat"
            >
              <div style={{ height: "75vh", overflow: "auto" }}>
                {message.length > 0 &&
                  message?.map((value) => {
                    return (
                      <>
                        {value.author_type === "Customer" && (
                          <div
                            style={{
                              margin: "0px",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: 400,
                              lineHeight: 1.5,
                              letterSpacing: "0.15px",

                              boxShadow:
                                "rgba(58, 53, 65, 0.2) 0px 2px 1px -1px, rgba(58, 53, 65, 0.14) 0px 1px 1px 0px, rgba(58, 53, 65, 0.12) 0px 1px 3px 0px",
                              borderRadius: " 0px 6px 6px",
                              fontSize: "0.875rem",
                              padding: "0.75rem 1rem",
                              backgroundColor: "rgb(255, 255, 255)",
                              width: "fit-content",
                              marginBottom: 20,
                            }}
                          >
                            {value.content}
                          </div>
                        )}
                        {value.author_type === "User" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-end",
                              margin: "0 0 0 auto",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: 400,
                              lineHeight: 1.5,
                              letterSpacing: "0.15px",
                              boxShadow:
                                " rgba(58, 53, 65, 0.2) 0px 2px 1px -1px, rgba(58, 53, 65, 0.14) 0px 1px 1px 0px, rgba(58, 53, 65, 0.12) 0px 1px 3px 0px",
                              borderRadius: "6px 0px 6px 6px",
                              fontSize: "0.875rem",
                              padding: "0.75rem 1rem",
                              backgroundColor: "rgb(145, 85, 253)",
                              width: "fit-content",
                              color: "rgb(255, 255, 255)",
                              marginBottom: 20,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span> {value.content}</span>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >

                                {value?.status === "viewed" ? (
                                  <DoneAllIcon fontSize="small" />
                                ) : (
                                  <DoneIcon fontSize="small" />
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    boxShadow:
                      " rgba(58, 53, 65, 0.2) 0px 2px 1px -1px, rgba(58, 53, 65, 0.14) 0px 1px 1px 0px, rgba(58, 53, 65, 0.12) 0px 1px 3px 0px",
                    padding: " 0.3125rem 1rem",

                    justifyContent: "space-between",
                    borderRadius: "6px",
                    backgroundColor: " rgb(255, 255, 255)",
                    width: "100%",
                  }}
                  className="send_reply"
                >
                  <TextField
                    placeholder="Type your message here…"
                    variant="outlined"
                    name="seat_count"
                    onChange={handleChange}
                    value={response}
                    style={{
                      width: "90%",
                    }}
                    required
                  />
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="success"
                    disabled={disable}
                    style={{ padding: "10px,15px" }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer style={{ zIndex: 9999999 }} />
    </Paper>
  );
}
export default memo(EnquiriesForm);
