import React from "react";
import DragBox from "assets/images/dragdrap.svg";

export const ImageViewer = ({ placeholder, onHandleClick, value }) => {
  return (
    <>
      <div
        onClick={onHandleClick}
        className="cursor-pointer"
        style={{ height: "85%", textAlign: "center" }}
      >
        {Array.isArray(value) ? (
          value.length !== 0 ? (
            <div
              style={{
                display: "flex",
                padding: "0.5rem",
                flexWrap: "wrap",
                overflow: "scroll",
                gap: "0.5rem",
                height: "210px",
                borderWidth: "4px",
                border: "2px solid #F7F7FA",
              }}
            >
              {value?.map((banner, index) => {
                return (
                  <div
                    key={index}
                    style={
                      value.length === 1
                        ? { width: "100%", height: "100%", objectFit: "cover" }
                        : { flex: "0 0 48%" }
                    }
                  >
                    <img
                      src={banner?.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "scale-down",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                alignItems: "center",
                backgroundColor: "#F7F7FA ",
                padding: "10px 0",
                borderRadius: "1rem",
                justifyContent: "center",
                height: "210px",
              }}
            >
              <div>
                <img src={DragBox} />
              </div>
              <div style={{ color: "#bb86fc" }}>
                <p>{placeholder}</p>
              </div>
            </div>
          )
        ) : value.id ? (
          <div
            style={{
              width: "100%",
              height: "210px",
              borderWidth: "4px",
              border: "2px solid #F7F7FA",
              padding: "0.5rem",
            }}
          >
            <img
              src={value?.url}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "scale-down",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              alignItems: "center",
              backgroundColor: "#F7F7FA",
              padding: "10px 0",
              borderRadius: "1rem",
              textAlign: "center",
              justifyContent: "center",
              // minHeight: "150px",
              // maxHeight: "200px",
              height: "210px",
            }}
          >
            <div>
              <img src={DragBox} />
            </div>
            <div style={{ color: "#bb86fc" }}>
              <p>{placeholder}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
