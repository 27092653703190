import makeRequest from "./index";

export function fetchUser(token, controller) {
  return makeRequest({
    uri: `/v1/admin/users`,
    method: "GET",
    authorization: token,
    controller: controller,
  });
}

export function fetchRole(token) {
  return makeRequest({
    uri: `/v1/admins/role_groups`,
    method: "GET",
    authorization: token,
  });
}

export function createUser(token, requestBody) {
  return makeRequest({
    uri: `/v1/admin/users`,
    method: "POST",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}

export function updateUser(token, requestBody, id) {
  return makeRequest({
    uri: `/v1/admin/users/${id}`,
    method: "PUT",
    authorization: token,
    body: JSON.stringify(requestBody),
  });
}
