import makeRequest from "./index";

export function fetchCustomers(token, params, search,controller) {
  return makeRequest({
    uri: `/v1/admin/customers?page=${params.page}&per_page=${params.perPage}&query=${search.query}`,
    method: "GET",
    authorization: token,
    controller:controller,
  });
}

export function customerDetails(token, id) {
  return makeRequest({
    uri: `/v1/admin/customers/${id}`,
    method: "GET",
    authorization: token,
  });
}
