import React, { useEffect, useState, memo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CustomButton from "../components/inputs/customButton";
import { Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { updateAccess, toastStyle, SliceContent } from "components/helper";
import { fetchOffers } from "api/offers";
import { getCookie, kickUser } from "components/helper/cookies";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createAccess } from "components/helper";
import PercentIcon from "@mui/icons-material/Percent";
import { Pagination } from "antd";
import noDataFound from "assets/images/notFound.svg";

const skeleton = [];
for (let i = 0; i < 9; i++) {
  skeleton.push(
    <div
      style={{
        flexBasis: "32%",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        color: "rgb(33, 43, 54)",
        borderRadius: " 16px",
        zIndex: 0,
        justifyContent: "space-between",
      }}
      className="card-body"
    >
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      <Skeleton
        animation="wave"
        height={15}
        style={{ marginBottom: 6, marginTop: 6 }}
        width={"70%"}
      />
    </div>
  );
}

function Offers({ handleLoader, searchName, handleSearchshow }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  const current = moment().format("YYYY-MM-DD");
  const [total_entries, setTotalEntries] = useState();
  const perPage = 25;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({ query: "" });
  const path = window.location.pathname.split("/")[1];
  let controller = new AbortController();
  useEffect(() => {
    handleSearchshow(true);
    handleLoader(true);
    setLoading(true);
    handleSearch({ target: { value: searchName } });
  }, [searchName]);
  useEffect(() => {
    handleSearch({ target: { value: "" } });
  }, [path]);
  useEffect(() => {
    handleLoader(true);
    setLoading(true);
    offers();
    return () => {
      controller.abort();
    };
  }, [page, search]);
  const offers = () => {
    handleLoader(true);
    setLoading(true);
    const params = {
      page,
      perPage,
    };
    fetchOffers(getCookie("auth_token"), params, search, controller)
      .then(({ data, total_entries }) => {
        setData(data);
        setLoading(false);
        handleLoader(false);
        setTotalEntries(total_entries);
      })
      .catch((err) => {
        if (err[0] === 403 || err[0] === 401) {
          toast.error(err[1].message, toastStyle);
          kickUser();
        }
      });
  };
  const handlePageChange = (page) => {
    setPage(page);
    setLoading(true);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch((preState) => ({ ...preState, query: value }));
  };
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
        height: "100%",
      }}
    >
      <Box
        style={{
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          Offers
          {total_entries > 0 && (
            <span style={{ color: "rgba(58, 53, 65, 0.87)" }}>
              ({total_entries})
            </span>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {createAccess("offers") && (
            <CustomButton
              color="success"
              text="Create Offer"
              onClick={() => navigate("/offers/createoffer")}
            />
          )}
        </div>
      </Box>

      <div style={{ padding: "1.2rem", height: "100%", minHeight: "80vh" }}>
        <Divider />
        {total_entries === 0 && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
              width: "100%",
            }}
          >
            <img src={noDataFound} style={{ width: "40%" }} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            gap: 10,
            flexWrap: "wrap",
            padding: "20px",
          }}
        >
          {loading ? (
            <>{skeleton}</>
          ) : (
            data.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flexBasis: "32%",
                    flexGrow: 0,
                    display: "flex",
                    padding: "10px 24px",
                    textAlign: "center",
                    backgroundColor: " rgb(255, 255, 255)",
                    color: "rgba(58, 53, 65, 0.87)",
                    transition:
                      "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    borderRadius: "6px",
                    overflow: "hidden",
                    boxShadow: " rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer",
                    filter:
                      value?.status === "inactive"
                        ? " grayscale(100%)"
                        : "none",
                    opacity: value?.status === "inactive" ? 0.6 : "none",
                  }}
                  className="card-body"
                  onClick={() =>
                    updateAccess("offers")
                      ? navigate(`/offers/${value.id}/updateOffer`)
                      : toast.error("Access Denied", toastStyle)
                  }
                >
                  <div
                    style={{
                      height: "100%",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      padding: 10,
                    }}
                  >
                    {/* <div style={{ flex: 3, height: "100%" }}> */}
                    <img
                      src={value?.en_image?.url}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "scale-down",
                        marginTop: 10,
                      }}
                    />
                    {/* </div> */}
                    <div
                      style={{
                        flex: 4,
                        height: "100%",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontFamily: "Inter, sans-serif",
                          fontSize: "1.2rem",
                          fontWeight: 600,
                          color: "rgba(58, 53, 65, 0.87)",
                          textAlign: "left",
                        }}
                      >
                        {SliceContent(value.en_title)}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      // display: "flex",
                      // justifyContent: "space-between",
                      // alignItems: "center",
                      // width: "100%",
                      position: "absolute",
                      top: 10,
                      right: "10px",
                    }}
                  >
                    {moment(current) > moment(value.expiry_date) ? (
                      <span
                        style={{
                          color: "rgb(255, 180, 0)",
                          backgroundColor: "rgba(255, 180, 0, 0.12)",
                          borderRadius: "10px",
                          padding: "5px",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 600,
                          fontSize: "0.87rem",
                        }}
                      >
                        Offer Expired
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "rgb(145, 85, 253)",
                          backgroundColor: "rgba(145, 85, 253, 0.12)",
                          borderRadius: "10px",
                          padding: "5px",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 600,
                          fontSize: "0.87rem",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <PercentIcon
                          fontSize="small"
                          style={{ fontWeight: 600 }}
                        />
                        Offer ends in :
                        <span style={{ fontWeight: 600 }}>

                          {moment
                            .duration(
                              moment(value.expiry_date).diff(moment(current))
                            )
                            .humanize()}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {total_entries != 0 && (
        <Pagination
          style={{ textAlign: "right", bottom: 30, marginRight: 20 }}
          defaultCurrent={1}
          current={page}
          defaultPageSize={perPage}
          onChange={handlePageChange}
          total={total_entries}
          showSizeChanger={false}
        />
      )}
      <ToastContainer style={{ zIndex: 999999999 }} />
    </Paper>
  );
}
export default memo(Offers);
