import React, { useState, useEffect, memo } from "react";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import CustomButton from "../inputs/customButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { getCookie } from "components/helper/cookies";
import { rentalCarCreate, rentalCarUpdate, rentalCarDetails } from "api/car";
import ImageModal from "components/images/Imagemodal";
import { ImageViewer } from "components/upload/imageViewer";
import { toast, ToastContainer } from "react-toastify";
import { TimeFormat, toastStyle } from "../helper";
import "react-toastify/dist/ReactToastify.css";
import { TinyMceField } from "components/inputs/tinymce";
import Paper from "@mui/material/Paper";
import { brands } from "config";
import { fetchOffers } from "api/offers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
function RentalForm({ handleSearchshow, handleLoader }) {
  const [stateData, setStateData] = useState({
    transmission: "automatic",
    fuel_type: "gas",
    car_type: "suv",
    brand: "",
    status: "active",
    offers: [],
  });
  const [showImageModal, setShowImageModal] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [disable, setDisable] = useState(false);
  const { id } = stateData;
  const [offersList, setOffersList] = useState([]);
  const title = id ? "Update Rental Car" : "Create Rental Car";
  const [search] = useState({ query: "" });

  const handleModel = (info) => {
    setShowImageModal(!showImageModal);
    setMultiSelect(info);
  };

  useEffect(() => {
    handleSearchshow(false);
    handleLoader(false);
    fetchOffer();
    const path = window.location.pathname.split("/")[2];
    path !== "new" && fetchmodelDetails(path);
  }, []);
  const fetchOffer = () => {
    handleLoader(true);
    const params = {
      page: 1,
      perPage: 100,
    };
    fetchOffers(getCookie("auth_token"), params, search)
      .then(({ data }) => {
        setOffersList(data);
        handleLoader(false);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        }
      });
  };
  const fetchmodelDetails = (id) => {
    rentalCarDetails(getCookie("auth_token"), id).then(({ data }) => {
      setStateData({
        ...data,
        offers: data.offers.map((val) => {
          return val.id;
        }),
      });
    });
  };
  const handleSubmit = () => {
    const { title_image, images } = stateData;
    setDisable(true);
    handleLoader(true);
    let requestBody = stateData?.id
      ? title_image && images
        ? {
            rentals: {
              ...{
                ...stateData,
                title_image_id: title_image.id,
                banner_image_id: images.map((value) => {
                  return value.id;
                }),
              },
            },
          }
        : toast.error("Please select the image", toastStyle)
      : title_image && images
      ? {
          rentals: {
            ...{
              ...stateData,
              title_image_id: title_image.id ? title_image.id : "",
              banner_image_id:
                images.length > 0
                  ? images.map((value) => {
                      return value.id;
                    })
                  : "",
            },
          },
        }
      : toast.error("Please select the image", toastStyle);
    const action = stateData?.id ? rentalCarUpdate : rentalCarCreate;
    action(getCookie("auth_token"), requestBody, stateData?.id)
      .then(({ message }) => {
        toast.success(message, toastStyle);
        handleLoader(false);
        setTimeout(() => {
          window.location.pathname = `/rentalcar`;
        }, 2000);
      })
      .catch((err) => {
        if (err[1]) {
          toast.error(
            err[1] ? err[1]?.data[0] : "Something not right",
            toastStyle
          );
        } else {
          toast.error(err.message, toastStyle);
        }
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStateData((preState) => ({ ...preState, [name]: value }));
    setDisable(false);
  };
  const handleToggle = (event, name) => {
    setStateData((preState) => ({ ...preState, [name]: event.target.value }));
    setDisable(false);
  };

  const onModalOk = (image_name) => {
    multiSelect
      ? setStateData({
          ...stateData,
          images: image_name.multiImageID,
          banner_image_id: image_name.selectedImage,
        })
      : setStateData({
          ...stateData,
          title_image: image_name,
          title_image_id: image_name.id,
        });
    setShowImageModal(!showImageModal);
    setDisable(false);
  };
  const imagedata = {
    showImageModal,
    handleModel,
    onModalOk,
    multi: multiSelect,
  };
  const { offers } = stateData;
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "rgb(244, 245, 250)",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(244, 245, 250)",
          padding: "1.25rem 1.25rem 0.75rem",
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            textTransform: "capitalize",
            margin: 0,
            fontFamily: "Inter, sans-serif",
            fontSize: "1.5rem",
            lineHeight: 1.334,
            letterSpacing: "0em",
            fontWeight: 500,
          }}
        >
          {title}
          <div
            style={{
              fontSize: "0.8rem",
              fontWeight: 500,
              color: "rgba(58, 53, 65, 0.38)",
              paddingTop: 10,
            }}
          >
            {stateData.updated_by
              ? `Updated by ${stateData.updated_by?.name}`
              : stateData.created_by
              ? `Created by ${stateData.created_by?.name}`
              : " "}
            {stateData.updated_by && stateData?.updated_at
              ? TimeFormat(stateData?.updated_at)
              : stateData?.created_by && stateData?.created_at
              ? TimeFormat(stateData?.created_at)
              : " "}
          </div>
        </div>
        <div>
          <CustomButton
            color="success"
            onClick={handleSubmit}
            text={id ? "Update Car" : "Create Car"}
            disabled={disable}
          />
        </div>
      </div>
      <Divider />

      <div
        style={{
          padding: "10px 20px ",
          backgroundColor: "rgb(255, 255, 255)",
          margin: "20px ",
          boxShadow: "rgba(58, 53, 65, 0.1) 0px 2px 10px 0px",
          borderRadius: " 16px",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
      >
        <Grid container spacing={3} style={{ padding: "24px 0" }}>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Car Brand</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData?.brand ? stateData?.brand : ""}
                label="Car Brand"
                onChange={handleChange}
                name="brand"
              >
                {brands?.map((value) => (
                  <MenuItem key={value.value} value={value.value}>
                    {value.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Car Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData?.car_type ? stateData?.car_type : ""}
                label="Car Type"
                name="car_type"
                onChange={(event) => handleToggle(event, "car_type")}
              >
                <MenuItem value={"mini"}>Mini</MenuItem>
                <MenuItem value={"sedan"}>Sedan</MenuItem>
                <MenuItem value={"suv"}>SUV</MenuItem>
                <MenuItem value={"others"}>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Model - En"
              variant="outlined"
              name="en_model_name"
              onChange={handleChange}
              value={stateData?.en_model_name ? stateData?.en_model_name : ""}
              style={{
                width: "100%",
              }}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="standard-basic"
              label="Model - Ar"
              variant="outlined"
              name="ar_model_name"
              onChange={handleChange}
              value={stateData?.ar_model_name ? stateData?.ar_model_name : ""}
              style={{
                width: "100%",
              }}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={2}>
            <TextField
              id="standard-basic"
              label="Seat Count"
              variant="outlined"
              name="seat_count"
              onChange={handleChange}
              value={stateData?.seat_count ? stateData?.seat_count : ""}
              style={{
                width: "100%",
              }}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="standard-basic"
              label="Horse Power"
              variant="outlined"
              name="horse_power"
              onChange={handleChange}
              value={stateData?.horse_power ? stateData?.horse_power : ""}
              style={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="standard-basic"
              label="Engine Power"
              variant="outlined"
              name="engine_pr"
              onChange={handleChange}
              value={stateData?.engine_pr ? stateData?.engine_pr : ""}
              style={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label"> Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateData?.status ? stateData?.status : ""}
                label="Status"
                name="status"
                onChange={(event) => handleToggle(event, "status")}
              >
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"inactive"}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label"> Offers</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={offers ? offers : ""}
                label="Offers"
                name="offers"
                onChange={(event) => handleToggle(event, "offers")}
                multiple
                MenuProps={MenuProps}
                // renderValue={(selected) => (
                //   <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                //     {selected.map((value) => (
                //       <Chip key={value} label={value} sx={{ m: 0.75 }} />
                //     ))}
                //   </Box>
                // )}
              >
                {offersList &&
                  offersList?.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.en_title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "24px 0" }}>
          <Grid
            container
            xs={6}
            columnSpacing={3}
            rowSpacing={4}
            style={{ padding: "24px 24px 0 24px" }}
            // columns={{ sm: 4, md: 12 }}
          >
            <Grid item xs={6} md={12} lg={6}>
              <p
                style={{
                  fontFamily: "Inter, sans-serif",
                  lineHeight: 1.5,
                  letterSpacing: "0.15px",
                  color: " rgba(58, 53, 65, 0.87)",
                  fontWeight: 400,
                  fontSize: " 1rem",
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Fuel Type
              </p>
              <ToggleButtonGroup
                exclusive
                onChange={(event) => handleToggle(event, "fuel_type")}
                color="primary"
                name="transmission"
                value={stateData?.fuel_type ? stateData?.fuel_type : ""}
              >
                <ToggleButton
                  value="gas"
                  // style={{ width: "100%", padding: "10px 40px" }}
                  style={{ width: "115px" }}
                >
                  Gas
                </ToggleButton>
                <ToggleButton
                  value="diesel"
                  // style={{ width: "100%", padding: "10px 40px" }}
                  style={{ width: "115px" }}
                >
                  Diesel
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6} md={12} lg={6}>
              <p
                style={{
                  fontFamily: "Inter, sans-serif",
                  lineHeight: 1.5,
                  letterSpacing: "0.15px",
                  color: " rgba(58, 53, 65, 0.87)",
                  fontWeight: 400,
                  fontSize: " 1rem",
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Transmission Type
              </p>
              <ToggleButtonGroup
                exclusive
                onChange={(event) => handleToggle(event, "transmission")}
                color="primary"
                name="transmission"
                value={stateData?.transmission ? stateData?.transmission : ""}
              >
                <ToggleButton value="automatic" style={{ width: "115px" }}>
                  Automatic
                </ToggleButton>
                <ToggleButton value="manual" style={{ width: "115px" }}>
                  Manual
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Price By Day"
                variant="outlined"
                name="day_price"
                value={stateData?.day_price ? stateData?.day_price : ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">OMR</InputAdornment>
                  ),
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Price By Fortnight"
                variant="outlined"
                name="fortnight_price"
                value={
                  stateData?.fortnight_price ? stateData?.fortnight_price : ""
                }
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">OMR</InputAdornment>
                  ),
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Price By Week"
                variant="outlined"
                name="weekly_price"
                value={stateData?.weekly_price ? stateData?.weekly_price : ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">OMR</InputAdornment>
                  ),
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-basic"
                label="Price By Month"
                variant="outlined"
                name="monthly_price"
                value={stateData?.monthly_price ? stateData?.monthly_price : ""}
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">OMR</InputAdornment>
                  ),
                }}
                required
              />
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: 1.5,
                letterSpacing: "0.15px",
                color: " rgba(58, 53, 65, 0.87)",
                fontWeight: 600,
                fontSize: " 1rem",
                textAlign: "center",
              }}
            >
              Title image
            </p>
            <ImageViewer
              placeholder="Upload Title Image"
              onHandleClick={() => {
                handleModel(false);
              }}
              name="title_image"
              value={stateData?.title_image ? stateData?.title_image : ""}
            />
          </Grid>

          <Grid item xs={3}>
            <p
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: 1.5,
                letterSpacing: "0.15px",
                color: " rgba(58, 53, 65, 0.87)",
                fontWeight: 600,
                fontSize: " 1rem",
                textAlign: "center",
              }}
            >
              Banner image
            </p>
            <ImageViewer
              placeholder="Upload Banner Image"
              onHandleClick={() => {
                handleModel(true);
              }}
              name="images"
              value={stateData?.images ? stateData?.images : ""}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} height={500} marginBottom={10}>
          <Grid item xs={6}>
            <p className="tinymce-title">Car Description - En</p>
            <TinyMceField
              value={stateData?.en_description ? stateData?.en_description : ""}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: "en_description",
                    value: e,
                  },
                });
              }}
              name="en_description"
              height="100%"
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <p className="tinymce-title">Car Description - Ar</p>
            <TinyMceField
              value={stateData?.ar_description ? stateData?.ar_description : ""}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: "ar_description",
                    value: e,
                  },
                });
              }}
              name="ar_description"
              height="100%"
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
      {showImageModal && <ImageModal {...imagedata} />}
      <ToastContainer style={{ zIndex: 9999999 }} />
    </Paper>
  );
}
export default memo(RentalForm);
