import React, { useEffect, useState } from "react";
import { filteredMenu } from "menu";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../loginModules/login";
import { getCookie } from "../helper/cookies";
import Forget from "components/loginModules/forget";
import ResetPassword from "components/loginModules/resetPassword";
import MainLayout from "components/layout/mainLayout";

const publicPath = [
  { path: "/", component: Login },
  { path: "/forgot-password", component: Forget },
  { path: "/reset-password/:id", component: ResetPassword },
];

export const CustomRoutes = () => {
  const [routeState, setRouteState] = useState({
    privatePath: [],
    loading: false,
  });
  const { privatePath, defaultRoute } = routeState;

  const filterMenu = async () => {
    const menu = await filteredMenu();

    setRouteState((preState) => ({
      ...preState,
      privatePath: menu,
      defaultRoute: menu[0]?.url,
      loading: true,
    }));
  };
  useEffect(() => {
    if (getCookie("auth_token")) {
      filterMenu();
    }else{
      setRouteState((prevState) => ({...prevState, defaultRoute:"/"}))
    }
  }, []);

  const roles = JSON.parse(window.localStorage.getItem("user_roles"));
  return (
    <Router>
      <Routes>
        {privatePath?.map((route, index) => {
          let Component = route?.render;
          return (
            <Route
              exact
              key={index}
              path={route?.url}
              element={
                getCookie("auth_token") && roles ? (
                  <MainLayout>
                    <Component />
                  </MainLayout>
                ) : (
                  <Navigate to={`/`} />
                )
              }
            />
          );
        })}

        {publicPath &&
          publicPath?.map((route, index) => {
            let Component = route?.component;
            return (
              <Route
                exact
                key={index}
                path={route?.path}
                element={
                  getCookie("auth_token") && roles ? (
                    <Navigate to={defaultRoute} />
                  ) : (
                    <Component />
                  )
                }
              />
            );
          })}

        <Route path="*" element={<Navigate to={defaultRoute} />} />
      </Routes>
    </Router>
  );
};
